import {
    Component,
    Input
}                                  from '@angular/core';

import {
    MobileCell,
    MobileCellLevelComponent
}                                  from '../mobile-cell/';

import { MobileCellTdscdmaCommon } from './';


@Component({
    selector:    'qp-mobile-cell-tdscdma-level',
    templateUrl: 'mobile-cell-tdscdma-level.component.html',
    styleUrls:   [
        'mobile-cell-tdscdma-level.component.css'
    ],
    imports:     [
    ]
})
export class MobileCellTdscdmaLevelComponent extends MobileCellLevelComponent
{
    //
    // Getters
    //

    public static override getLevelToolTip(d: MobileCell): string
    {
        return super.getLevelToolTip(d);
            // + (d instanceof MobileCellTdscdmaCommon
            //     ? (d.rsrp ? "\nRsrp: " + d.rsrp : "")
            //         + (d.rsrq ? "\nRsrq: " + d.rsrq  : "")
                
            //     : ""
            // );
    }
}