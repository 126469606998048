@if (text) {
    <qp-table-data
        [nullValues]=false
        [data]="
            [
                { k: 'Device Id', v: text, c: link ? 'link' : ''}
            ]
        "

        (selected)="select($event)"
    >
    </qp-table-data>
    <!-- <mat-icon class="tab-icon">{{ Icons.link }}</mat-icon> -->
}