import {
   Component,
   ComponentRef,
   EventEmitter,
   Input,
   Output
}                               from '@angular/core';
import { RouterModule }         from '@angular/router';
import { BaseComponent }        from '@Base/';
import { MaterialCustomModule } from '@Material/';
import { SideBarComponent }     from '@Navigation/';

import {
   MatBottomSheet,
   MatBottomSheetRef
}                               from '@angular/material/bottom-sheet';


@Component({
    selector:    'qp-main-desktop',
    templateUrl: 'main-desktop.component.html',
    styleUrls:   [
        'main-desktop.component.css'
    ],
    imports:     [
        MaterialCustomModule,
        RouterModule
    ]
})
export class MainDesktopComponent extends BaseComponent
{
   @Output()
   private selected:       EventEmitter<boolean> = new EventEmitter();

   private routeComponent: ComponentRef<any>;


   constructor(private readonly _bottomSheet: MatBottomSheet)
   {
       super();
   }


   public onClick(event: any): void
   {
      this.selected.emit(event);
      if (this.routeComponent instanceof SideBarComponent) (this.routeComponent as SideBarComponent).onClick(event);
   }


   public onRouteActivated(componentRef: ComponentRef<any>): void
   {
      this.routeComponent = componentRef;
   }

   // openBottomSheet(): void {
   //    this._bottomSheet.open(BottomSheetOverviewExampleSheet);
   //  }
}


@Component({
    selector: 'bottom-sheet-overview-example-sheet',
    template: `
    <mat-nav-list>
        <a href="https://keep.google.com/" mat-list-item (click)="openLink($event)">
            <span mat-line>Google Keep</span>
            <span mat-line>Add to a note</span>
        </a>
    </mat-nav-list>`,
    imports:     [
        MaterialCustomModule
     ]
})
export class BottomSheetOverviewExampleSheet
 {
   constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetOverviewExampleSheet>) {}

   openLink(event: MouseEvent): void
   {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
   }
}