import { CommonModule }         from '@angular/common';

import {
    Component,
    Input,
}                               from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';
import {
    // MobileCell,
    // MobileCellSignalCategories,
    MobileCellHelper
}                               from '@ObjElements/mobile-cells/mobile-cell-helper.class';

import {
    MobileCellCommon as MobileCell,
    MobileCellSignalCategories,
}                                   from '@Common/Elements/MobileCell/';


import { ButtonBaseComponent }  from './button-base.component';


// Values here need to match associated CSS
enum MobileCellLevelIconSignalState {
    Great   = 'great',
    Good    = 'good',
    Average = 'average',
    Poor    = 'poor',
    Bad     = 'bad',
    None    = 'none'
}; // MobileCellLevelIconSignalState


@Component({
    selector:     'qp-mobilecell-level-icon',
    templateUrl:  'mobilecell-level-icon.component.html',
    styleUrls:   [
        'mobilecell-level-icon.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class MobileCellLevelIconComponent extends ButtonBaseComponent
{
    private static readonly _iconDefault:    string  = IconsMaterial.mobile_signal_level;

    private static readonly _iconGreat:      string  = IconsMaterial.mobile_signal_level_4_bar;
    private static readonly _iconGood:       string  = IconsMaterial.mobile_signal_level_4_bar;
    private static readonly _iconAverage:    string  = IconsMaterial.mobile_signal_level_2_bar;
    private static readonly _iconPoor:       string  = IconsMaterial.mobile_signal_level_2_bar;
    private static readonly _iconBad:        string  = IconsMaterial.mobile_signal_level_1_bar;

    private static readonly _icons: Map<MobileCellSignalCategories, string> = new Map<MobileCellSignalCategories, string>([
        [MobileCellSignalCategories.Great,   MobileCellLevelIconComponent._iconGreat],
        [MobileCellSignalCategories.Good,    MobileCellLevelIconComponent._iconGood],
        [MobileCellSignalCategories.Average, MobileCellLevelIconComponent._iconAverage],
        [MobileCellSignalCategories.Poor,    MobileCellLevelIconComponent._iconPoor],
        [MobileCellSignalCategories.Bad,     MobileCellLevelIconComponent._iconBad]
    ]); // _icons

    private static readonly _states: Map<MobileCellSignalCategories, string> = new Map<MobileCellSignalCategories, string>([
        [MobileCellSignalCategories.Great,   MobileCellLevelIconSignalState.Great],
        [MobileCellSignalCategories.Good,    MobileCellLevelIconSignalState.Good],
        [MobileCellSignalCategories.Average, MobileCellLevelIconSignalState.Average],
        [MobileCellSignalCategories.Poor,    MobileCellLevelIconSignalState.Poor],
        [MobileCellSignalCategories.Bad,     MobileCellLevelIconSignalState.Bad]
    ]); // _states

    // let myMap = new Map<string, string>([
    //     ["key1", "value1"],
    //     ["key2", "value2"]
    // ]);


    private                 _icon:           string  = "";
    private                 _signalQuality:  string  = "";
    private                 _signalStrength: string  = "";
    private                 _tooltip:        string  = "";


    @Input()
    public                  large:           boolean = false;

    @Input()
    public                  mobilecell:      MobileCell;


    //
    // Getters and setters
    //
    public get ngClass(): string
    {
        return this.signalQuality + " " + (this.large ? 'large' : '');
    }

    
    @Input()
    public get icon(): string
    {
        return this._icon
            ? this._icon
            : MobileCellLevelIconComponent.getIcon(this.signalStrengthCategory);
    }

    public set icon(d)
    {
        this._icon = d;
    }


    // Use signal quality to determine colour of bars
    @Input()
    public get signalQuality(): string
    {
        return (this.mobilecell instanceof MobileCell)
            ? MobileCellLevelIconComponent.getState(this.mobilecell.signalqualitycategory)
            : this._signalQuality;
    }

    public set signalQuality(d: string)
    {
        this._signalQuality = d;
    }


    // Use signal strength to determine number of bars
    @Input()
    public get signalStrength(): string
    {
        return (this.mobilecell instanceof MobileCell)
            ? MobileCellLevelIconComponent.getState(this.mobilecell.signalstrengthcategory)
            : this._signalStrength;
    }

    public set signalStrength(d: string)
    {
        this._signalStrength = d;

    }

    private get signalStrengthCategory()
    {
        return (this.mobilecell instanceof MobileCell)
            ? this.mobilecell.signalstrengthcategory
            : MobileCellSignalCategories.Invalid
    }


    @Input()
    public get tooltip(): string
    {
        return (this.mobilecell instanceof MobileCell)
            ? MobileCellHelper.getLevelToolTip(this.mobilecell)
            : this._tooltip;
    }

    public set tooltip(d: string)
    {
        this._tooltip = d;
    }


    //
    // Private methods
    //
    private static getIcon(d: MobileCellSignalCategories | undefined): string
    {
        const i: string | undefined = (d instanceof MobileCellSignalCategories) ? MobileCellLevelIconComponent._icons.get(d) : undefined;
        return (i) ? i : MobileCellLevelIconComponent._iconDefault
        // if (d instanceof MobileCellSignalCategories) {
        //     switch (d) {
        //         case MobileCellSignalCategories.Great:   return MobileCellLevelIconComponent._iconGreat;
        //         case MobileCellSignalCategories.Good:    return MobileCellLevelIconComponent._iconGood;
        //         case MobileCellSignalCategories.Average: return MobileCellLevelIconComponent._iconAverage;
        //         case MobileCellSignalCategories.Poor:    return MobileCellLevelIconComponent._iconPoor;
        //         case MobileCellSignalCategories.Bad:     return MobileCellLevelIconComponent._iconBad;

        //         case MobileCellSignalCategories.Invalid:
        //         default:
        //             // Fall through
        //     } // switch
        // }

        // return MobileCellLevelIconComponent._iconDefault;
    }


    private static getState(d: MobileCellSignalCategories | undefined): string
    {
        const i: string | undefined = (d instanceof MobileCellSignalCategories) ? MobileCellLevelIconComponent._states.get(d) : undefined;
        return (i) ? i : MobileCellLevelIconSignalState.None
        // if (d instanceof MobileCellSignalCategories) {
        //     switch (d) {
        //         case MobileCellSignalCategories.Great:   return MobileCellLevelIconSignalState.Great;
        //         case MobileCellSignalCategories.Good:    return MobileCellLevelIconSignalState.Good;
        //         case MobileCellSignalCategories.Average: return MobileCellLevelIconSignalState.Average;
        //         case MobileCellSignalCategories.Poor:    return MobileCellLevelIconSignalState.Poor;
        //         case MobileCellSignalCategories.Bad:     return MobileCellLevelIconSignalState.Bad;

        //         case MobileCellSignalCategories.Invalid:
        //         default:
        //             // Fall through
        //     } // switch
        // }

        // return MobileCellLevelIconSignalState.None;
    }
}