import { CommonModule }         from '@angular/common';
import {
    Component,
    VERSION
}                               from '@angular/core';
import { Observable }           from 'rxjs';

import { BaseComponent }        from '@Base/';
import { IconsMaterial }        from '@Icons/icons.class';
import { MaterialCustomModule } from '@Material/';
import { Dateq }                from '@Utils/';

import { ClientConfigService }  from '../Services/';


// import { FormControl } from '@angular/forms';
@Component({
    selector:    'qp-about',
    templateUrl: 'about.component.html',
    styleUrls:   [
        'about.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule,
    ]
})
export class AboutComponent extends BaseComponent
{
    public readonly showAngularVersion: boolean            = false; //true;
    public readonly showEmail:          boolean            = false; //true;
    public readonly showUrl:            boolean            = true;

    public readonly iconEmail:          string             = IconsMaterial.email;
    public readonly iconWebLink:        string             = IconsMaterial.web_link_open_in_new;

    public          cd:                 any                = {};
    public          cDate:              string             = "";
    public          company:            string | undefined;
    public          companyUrl:         string | undefined;
    public          name:               string | undefined;
    public readonly today:              Dateq  | undefined = Dateq.get(undefined, true);
    public          version:            string | undefined;
    // selectedFW = new FormControl();
    

    constructor(private readonly ClientConfigService: ClientConfigService)
    {
        super();
    }


    //
    // Getter
    //
    public get angularVersion(): string
    {
        return VERSION ? VERSION.full : "";
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        if (this.ClientConfigService) {
            const obs: Observable<any> | undefined = this.ClientConfigService.version$;
            if (obs instanceof Observable) this.sub = obs
                .subscribe((d: any): void => this.update(d)
            ); // subscribe()
                
            this.cd = this.ClientConfigService.getCompanyDetails();
            this.cd = this.cd ? this.cd : {};
            if (this.cd && this.cd.icon) {
                try {
                    //this.cd.icon = require(this.cd.icon);
                    // console.debug(this.cd);
                }
                catch (e) {
                    console.warn(e);
                }
            }
        }
    }


    //
    // Private methods
    //
    private update(d: any)
    {
        if (d) {
            const t: Dateq | undefined = Dateq.get(+d.getTimestamp()); //  unary '+' required to convert to number
            this.cDate = t ? t.displayStr : this.cDate;

            this.company    = d.getCompany();//this.VersionService.getCompany();
            this.companyUrl = d.getCompanyUrl();
            this.name       = d.getName();
            this.version    = d.getVersion();
        }
    }
}