import { Routes }                 from '@angular/router';

import { AuthenticationGuard }    from '@Login/';

import { MainComponent }          from './main.component';

import { IsDesktopOrMobileGuard } from './is-desktop-or-mobile-guard.service';


export const mainRoutes: Routes = [
    {
        path:             '',
        component:        MainComponent,
        canActivate:      [ AuthenticationGuard ],
        children: [
            {
                path:        '',
                // component:   IsDesktopOrMobileGuard.isMobile() ? MainMobileComponent : MainDesktopComponent,
                canActivate: [ AuthenticationGuard ],
                loadChildren: async () => (
                    false//IsDesktopOrMobileGuard.isMobile()
                        ? (await import('./mobile/main-mobile.routes')).mainMobileRoutes
                        : (await import('./desktop/main-desktop.routes')).mainDesktopRoutes
                    ),
                data: { preload: true }
                // loadChildren: async () => (
                //     IsDesktopOrMobileGuard.isMobile()
                //         ? (await import('./mobile/main-mobile.module')).MainMobileModule
                //         : (await import('./desktop/main-desktop.module')).MainDesktopModule
                //     )
                // data: { mobile: true }
            }

                // // Desktop
                // : {
                //     path:        '',
                //     canActivate: [ AuthenticationGuard ],
                //     loadChildren: async () => (await import('@SideBar/sidebar.module')).SideBarModule, // better - needs Ivy
                //     // loadChildren: () => import('@SideBar/sidebar.module').then(m => m.SideBarModule)
                //     data: { mobile: false }
                // }
        ]
    }
];