import { CommonModule }         from '@angular/common';
import {
    Component,
    Input
}                               from '@angular/core';

import { BaseComponent }        from '@Base/';
import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';

// TBD
import { SurveyStatus }         from '@Common/Elements/Survey/';
// import { SurveyStatus }         from '@ObjElements/survey/';


// Values here need to match associated CSS
export enum SurveyStatusState {
    Completed = 'completed',
    Paused    = 'paused',
    Started   = 'started',
    Stopped   = 'stopped',
    Unknown   = 'unknown'
}; // SurveyStatusState


const SurveyIconUnknown: string = IconsMaterial.survey_unknown;
const SurveyIcons = new Map<SurveyStatusState, string>([
    [SurveyStatusState.Completed, IconsMaterial.survey_completed],
    [SurveyStatusState.Paused,    IconsMaterial.survey_paused],
    [SurveyStatusState.Started,   IconsMaterial.survey_started],
    [SurveyStatusState.Stopped,   IconsMaterial.survey_stopped],
    [SurveyStatusState.Unknown,   SurveyIconUnknown],
]); // Map


@Component({
    selector:    'qp-status-icon-survey',
    templateUrl: 'status-icon-survey.component.html',
    styleUrls:   [
       'status-icon-survey.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class StatusIconSurveyComponent extends BaseComponent
{
    public  icon:   string            = IconsMaterial.status_checked;

    private _state: SurveyStatusState = SurveyStatusState.Unknown;


    //
    // Getters and setters
    //
    public get state(): SurveyStatusState
    {
        return this._state;
    }


    @Input()
    public set state(d: SurveyStatusState | SurveyStatus)
    {
        if (d) {
            this._state = (d instanceof SurveyStatus) ? StatusIconSurveyComponent.get(d) : d;
            const i: string | undefined = SurveyIcons.get(this._state);
            this.icon = i ? i : SurveyIconUnknown;
        }
    }


    private static get(d: SurveyStatus | undefined): SurveyStatusState
    {
        if (d instanceof SurveyStatus) switch (d) {
            case SurveyStatus.Completed: return SurveyStatusState.Completed;
            case SurveyStatus.Paused:    return SurveyStatusState.Paused;
            case SurveyStatus.Started:   return SurveyStatusState.Started;
            case SurveyStatus.Stopped:   return SurveyStatusState.Stopped;

            case SurveyStatus.Unknown:
            default:
                // Fall through
        } // switch

        return SurveyStatusState.Unknown;
    }
}