import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';
import { TableDataComponent }   from '@GuiElements/table-data.component';

import { TestIcmp }             from '../';


@Component({
    selector:    'qp-test-icmp-info',
    templateUrl: 'test-icmp-info.component.html',
    styleUrls:   [
        'test-icmp-info.component.css'
    ],
    imports:    [
        TableDataComponent
    ]
})
export class TestIcmpInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:        any;

    @Input()
    public dataSource2: any;

    @Input()
    public nullValues:  boolean = true;

    
    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }
}