import { Component }                from '@angular/core';

import { BaseComponent }            from '@Base/';
import { ProgressSpinnerComponent } from '@GuiElements/progress-spinner.component';
import { MaterialCustomModule }     from '@Material/';


@Component({
    selector:    'qp-progress-spinner-c',
    templateUrl: 'progress-spinner-dialog.component.html',
    styleUrls:   [
       'progress-spinner-dialog.component.css'
    ],
    imports:     [
       ProgressSpinnerComponent,

       MaterialCustomModule
    ]
})
export class ProgressSpinnerDialogComponent extends BaseComponent
{

}