import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';
import { TableDataComponent }   from '@GuiElements/table-data.component';

import { MobileCellLteCommon }  from './';


@Component({
    selector:    'qp-mobile-cell-lte-info',
    templateUrl: 'mobile-cell-lte-info.component.html',
    styleUrls:   [
        'mobile-cell-lte-info.component.css'
    ],
    imports:     [
        TableDataComponent
    ]
})
export class MobileCellLteInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:        any;

    @Input()
    public dataSource2: any;

    @Input()
    public nullValues:  boolean = true;

    
    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }
}