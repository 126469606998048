import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';
import { TableDataComponent }   from '@GuiElements/table-data.component';

import { MobileCellNrCommon }   from './';


@Component({
    selector:    'qp-mobile-cell-nr-info',
    templateUrl: 'mobile-cell-nr-info.component.html',
    styleUrls:   [
        'mobile-cell-nr-info.component.css'
    ],
    imports:     [
        TableDataComponent
    ]
})
export class MobileCellNrInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:       MobileCellNrCommon;

    @Input()
    public nullValues: boolean             = true;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }
}