<qp-window-header
    [title]="title"
    [value]="showDataQuantity ? dataQuantity : undefined"
    
    (refresh)="refresh()">
</qp-window-header>

<!--     <div class="buttonAndTextInner2">
        <button mat-raised-button color="primary" (click)="showOnMap2()" matTooltip="Show filtered sightings on map">
            <mat-icon aria-label="Reload">map</mat-icon>
        </button>
    </div> -->

<mat-form-field *ngIf="dataTypes.length > 1">
    <mat-label>Type</mat-label>
    <mat-select
       multiple
        [formControl]="list"
        (selectionChange)="selectionChanged($event)">

        <mat-select-trigger>
            {{ list.value ? list.value[0] : '' }}
            <span *ngIf="list.value?.length > 1" >
                (+{{ list.value.length - 1 }} {{ list.value?.length === 2 ? 'other' : 'others' }})
            </span>
        </mat-select-trigger>
        <mat-option *ngFor="let dt of dataTypes" [value]="dt">{{ dt }}</mat-option>
    </mat-select>
</mat-form-field>

<qp-list-data [hidden]="dataQuantity <= 0"
    [attrs]="attrsSrc"
    [checked]="checked"
    [data]="dataSrc"
    [dataQuantity]="dataQuantity"
    [pageSizeDefault]="pageSize"
    [searchEnabled]="searchEnabled"
    [selectEnabled]="selectEnabled"
    
    (filterChanged)="filterChanged($event)"
    (pageChanged)="pageChanged($event)"
    (selected)="select($event)"
    (selectedM)="selectM($event)"
    (sortChanged)="sortChanged($event)">
</qp-list-data>