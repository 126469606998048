import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';
import { SurveysService }       from '@Surveys/service/';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
    selector:    'qp-sidebar-surveys',
    templateUrl: 'sidebar-surveys.component.html',
    styles:      [
        'sidebar-surveys.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class SideBarSurveysComponent extends SideBarBaseComponent
{
    constructor(private readonly SurveysService: SurveysService)
    {
        super(IconsMaterial.surveys, SurveysService, true);
    }
}