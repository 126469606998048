// DEPRECATED

import { CommonModule }            from '@angular/common';
import { FlexLayoutModule }        from '@angular/flex-layout';
import { NgModule }                from '@angular/core';

// include qp-list-data and qp-window-header
// import {
//     GuiElementsModule,
//     // ListDataComponent,
//     // ProgressSpinnerComponent
// }                                  from '@GuiElements/';
// import { MaterialCustomModule }    from '@Material/';

// import { 
//     // AboutComponent,
//     // DialogComponent,
//     // ListComponent,
//     // ProgressSpinnerDialogComponent
// }                                  from './Components/';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,

        // GuiElementsModule,
        // MaterialCustomModule,

        // ListDataComponent,
        // ProgressSpinnerComponent
    ],
    
    declarations: [
    //     // AboutComponent,
    //     DialogComponent,
        // ListComponent,
        // ProgressSpinnerDialogComponent
    ],

    providers: [
    ],

    exports: [
        // AboutComponent,
        // ListComponent
    ]
})
export class MiscModule { }