import { CommonModule }         from '@angular/common';

import { Component }            from '@angular/core';

import { ElementInfoComponent } from '../../../element/element/element-info.component';
import { MaterialCustomModule } from '@Material/';

import { ConnectionCommon }     from '@Common/Elements/Devices/';
import { TableDataComponent }   from '@RootGuiElements/table-data.component';


@Component({
    selector:     'qp-connection-info',
    templateUrl:  'connection-info.component.html',
    styleUrls:   [
        'connection-info.component.css'
    ],
    imports:     [
        TableDataComponent,

        CommonModule,
        MaterialCustomModule
    ]
})
export class ConnectionInfoComponent extends ElementInfoComponent
{
    //
    // Getters
    //
    public get d(): ConnectionCommon
    {
        return this.data as ConnectionCommon;
    }
}