import { Injectable }        from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subscription,

    take
}                            from 'rxjs';

import {
    MessageService,
    MessageProcessingService
}                            from '@Messaging/';

import { ElementService }    from './element.service';


@Injectable({
   providedIn: null//'root' - not singleton; allow components to scope this
})
export class ElementsService extends ElementService
{
    constructor(MessageService:           MessageService,
                MessageProcessingService: MessageProcessingService)
    {
        super(MessageService, MessageProcessingService);
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Elements service");
    }
}