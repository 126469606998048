import { CommonModule }         from '@angular/common';
import {
    Component,
    Input
}                               from '@angular/core';

import { BaseComponent }        from '@Base/';
import { MaterialCustomModule } from '@Material/';


@Component({
    selector:    'qp-data-element-item',
    templateUrl: 'data-element.component.html',
    styleUrls:   [
        'data-element.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class DataElementComponent extends BaseComponent
{
    @Input()
    public light: boolean = false;

    @Input()
    public key:   string  = "";

    @Input()
    public text:  string  = "";
}