
@if (text) {
    @if (key) {
        <mat-list-item [ngClass]="{ 'light' : light, 'key-item': true}"><b><b>{{ key }}</b></b>: {{ text }}</mat-list-item>
    }
    @else {
        <mat-list-item [ngClass]="{ 'light' : light }">{{ text }}</mat-list-item>
    }
}


<!-- <div *ngIf="text">
    <div *ngIf="key">
        <mat-list-item [ngClass]="{ 'light' : light, 'key-item': true}"><b><b>{{ key }}</b></b>: {{ text }}</mat-list-item>
    </div>
    
    <div *ngIf="! key">
        <mat-list-item [ngClass]="{ 'light' : light }">{{ text }}</mat-list-item>
    </div>
</div> -->