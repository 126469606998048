import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../../element/element/element-info.component';
import { DataElementComponent } from '@GuiElements/data-element.component';
import { MaterialCustomModule } from '@Material/';

import { DeviceWithWifi }       from './device-with-wifi.class';


@Component({
    selector:    'qp-device-with-wifi-info',
    templateUrl: 'device-with-wifi-info.component.html',
    styleUrls:   [
        'device-with-wifi-info.component.css'
    ],
    imports:     [
        DataElementComponent,

        CommonModule,
        MaterialCustomModule
    ]
})
export class DeviceWithWifiInfoComponent extends ElementInfoComponent
{
    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): DeviceWithWifi
    {
        return this.data as DeviceWithWifi;
    }
}