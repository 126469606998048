import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';

import { DeviceGroupsService }  from '@DeviceGroups/device-groups.service';
import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';

import { SideBarBaseComponent } from './sidebar-base.component';

import { AppStrings }           from '@Root/';


@Component({
    selector:    'qp-sidebar-devicegroups',
    templateUrl: 'sidebar-devicegroups.component.html',
    styles:      [
        'sidebar-devicegroups.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class SideBarDeviceGroupsComponent extends SideBarBaseComponent
{
    public readonly name = AppStrings.DeviceGroups;


    constructor(DeviceGroupsService: DeviceGroupsService)
    {
        super(IconsMaterial.device_groups, DeviceGroupsService); // [TBD]
    }
}