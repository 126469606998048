import {
    DeviceGroup,
    DeviceGroupInfoComponent
}                                 from '../device-group/';

import {
    Device,
    DeviceCommon as DeviceC,
    DeviceInfoContainerComponent
}                                 from '../devices/';

import {
    MobileCell} from '../mobile-cells/';
import {
    MobileCellInfoComponent
}                                 from '../mobile-cells/mobile-cell/mobile-cell-info.component';

import {
    Notification,
    NotificationInfoComponent
}                                 from '../notification/';

import {
    Organisation,
    OrganisationInfoComponent
}                                 from '../organisation/';

import {
    Site,
    SiteInfoComponent
}                                 from '../site/';

import {
    Survey,
    SurveyInfoComponent,

    SurveyPoint,
    SurveyPointInfoComponent,

    SurveySighting,
    SurveySightingInfoComponent
}                                 from '../survey/';

import {
    Test,
    TestInfoComponent
}                                 from '../test/';

import {
    WifiCell,
    WifiCellInfoComponent
}                                 from '../wifi-cells/';


import { 
    Element,
    ElementInfoComponent
}                                 from './element/';



// enum deviceTypes {
//     phone    = 'phone',
//     repeater = 'repeater',
//     beacon   = 'beacon'
// };

// [TBD]
export enum ElementTag {
    Device         = 'device',
    Devicegroup    = 'devicegroup',
    Mobilecell     = 'mobilecell',
    Notification   = 'notification',
    Organisation   = 'organisation',
    Site           = 'site',
    Survey         = 'survey',
    SurveyPoint    = 'point',
    SurveySighting = 'sightings',

    Test           = 'test',
    Wificell       = 'wificell',

    Element        = 'element'
} // ElementTag


export class ElementHelper
{
    public static getInfoType(d: any): typeof ElementInfoComponent | undefined
    {
        if      (d instanceof Device || d instanceof DeviceC)
                                              return DeviceInfoContainerComponent;// DeviceHelper.getInfoType(d as Element);

        else if (d instanceof DeviceGroup)    return DeviceGroupInfoComponent;
        else if (d instanceof MobileCell)     return MobileCellInfoComponent;//MobileCellHelper.getInfoType(d as MobileCell);
        else if (d instanceof Notification)   return NotificationInfoComponent;
        else if (d instanceof Organisation)   return OrganisationInfoComponent;
        else if (d instanceof Site)           return SiteInfoComponent;

        else if (d instanceof Survey)         return SurveyInfoComponent;
        else if (d instanceof SurveyPoint)    return SurveyPointInfoComponent;
        else if (d instanceof SurveySighting) return SurveySightingInfoComponent;

        else if (d instanceof Test)           return TestInfoComponent;
        else if (d instanceof WifiCell)       return WifiCellInfoComponent;

        else if (d instanceof Element)        return ElementInfoComponent;

        return undefined;
    }


    // [TBD]
    public static getTag(d: any): string
    {
        if      (d instanceof Device || d instanceof DeviceC)
                                              return ElementTag.Device;

        else if (d instanceof DeviceGroup)    return ElementTag.Devicegroup;
        else if (d instanceof MobileCell)     return ElementTag.Mobilecell;
        else if (d instanceof Notification)   return ElementTag.Notification;
        else if (d instanceof Organisation)   return ElementTag.Organisation;
        else if (d instanceof Site)           return ElementTag.Site;

        else if (d instanceof Survey)         return ElementTag.Survey;
        else if (d instanceof SurveyPoint)    return ElementTag.SurveyPoint;
        else if (d instanceof SurveySighting) return ElementTag.SurveySighting;
        
        else if (d instanceof Test)           return ElementTag.Test;
        else if (d instanceof WifiCell)       return ElementTag.Wificell;

        else if (d instanceof Element)        return ElementTag.Element;

        return "";
    }


    public static getTitle(d: any): string
    {
        if      (d instanceof Device) return d.markerTitle;
        //// else if (d instanceof MobileCell)   return ElementTag.Mobilecell;
        //// else if (d instanceof Notification) return ElementTag.Notification;
        // else if (d instanceof Organisation) return ElementHelper.getTag(d) + ": " + d.name;

        else if (d instanceof Site)  return ElementHelper.getTag(d) + ": "
             + ((d as any).organisation ? (d as any).organisation + " - " : "") + d.name;

        else if (
               (d instanceof DeviceC)
            || (d instanceof DeviceGroup)
            || (d instanceof Organisation)
            || (d instanceof Survey)
        ) return ElementHelper.getTag(d) + ": " + (d.name ? d.name : d.id);

        else if (d instanceof SurveyPoint) return ElementTag.Survey + " " + ElementTag.SurveyPoint + ": " + d.id + (d.name ? " - " + d.name : '')

        else if (d instanceof Element) return d.markerTitle;

        return "";
    }
}