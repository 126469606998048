import {
    Component,
    Input
}                        from '@angular/core';

import {
    ButtonBaseComponent,
    ButtonComponent
}                        from '@GuiElements/';
import { IconsMaterial } from '@Icons/';
import { Utils }         from '@Utils/';

// import { Test }          from './';
import {
    Test
}                                   from '@Common/Elements/Tests/';


@Component({
    selector:     'qp-test-icon',
    templateUrl:  'test-icon.component.html',
    styleUrls:   [
        'test-icon.component.css'
    ],
    imports:     [
        ButtonComponent
    ]
})
export class TestIconComponent extends ButtonBaseComponent
{
    private static readonly _icons: Record<string, string> = {
        [Test.Types.dns]:           IconsMaterial.test,
        [Test.Types.icmp]:          IconsMaterial.test,
        [Test.Types.ftp]:           IconsMaterial.test,
        [Test.Types.sftp]:          IconsMaterial.test,
        [Test.Types.udp]:           IconsMaterial.test,
        [Test.Types.voice]:         IconsMaterial.test,
        [Test.Types.web]:           IconsMaterial.test,
        [Test.Types.youtube]:       IconsMaterial.test,

        [Test.Directions.downlink]: IconsMaterial.test_downlink,
        [Test.Directions.uplink]:   IconsMaterial.test_uplink
    }; // _icons

    private _icon:  string | undefined = "";
    private _name2: string = "";
    private _type:  string = "";


    @Input()
    public data:    Test | undefined;


    //
    // Getters and setters
    //
    @Input()
    public get icon(): string
    {
        if (this._icon) return this._icon;
        else {
            let i = TestIconComponent.getIconDirection(this.data);
            return (this._icon = i ? i : TestIconComponent.getIcon(this.data));
        }
        // return this._icon
        //     ? this._icon
        //     : (this._icon = TestIconComponent.getIconDirection(
        //     // : (this._icon = TestIconComponent.getIcon(
        //         this.data
        //     )); // getIcon
    }

    public set icon(d)
    {
        this._icon = d;
    }


    @Input()
    public get name2(): string
    {
        return (this._name2)
            ? this._name2
            : (this._name2 = TestIconComponent.getName(
                this.data
            )); // getName
    }

    public set name2(d)
    {
        this._name2 = d;
    }


    @Input()
    public get type(): string
    {
        return (this._type)
            ? this._type
            : ((this.data instanceof Test)
                ? this.data.type
                : this._type
            );
    }

    public set type(d: string)
    {
        this._type = d;
    }


    //
    // Public methods
    //
    public getCssClass(): string
    {
        return "";
        // return (this.technology === MobileCellTechnology.lte)
        //     ? (this.is5g() ? MobileCellTechnology.nr_nsa : this.technology)
        //     : this.technology;
    }


    //
    // Private methodss
    //
    private static getIcon(d: string | Test | undefined, e?: string): string
    {
        //     return (d instanceof MobileCell) ? MobileCellHelper.getIcon(d) : "";

        const t:  string = (d instanceof Test) ? d.type : d;

        const i: string | undefined = TestIconComponent._icons[t];
        return (i) ? i : IconsMaterial.test;
    }


    private static getIconDirection(d: string | Test | undefined, e?: string): string | undefined
    {
        //     return (d instanceof MobileCell) ? MobileCellHelper.getIcon(d) : "";

        const t: string = ((d instanceof Test) ? ((d as any)?.direction ? (d as any).direction : "") : d);

        return TestIconComponent._icons[t ? t.toLocaleLowerCase() : t];
        // return (i) ? i : IconsMaterial.test;
    }


    private static getName(d: string | Test | undefined, e?: string): string
    {
        return ((d instanceof Test) ? (d.type?.name ? d.type.name : d.type) : (d ? d : ""));
    }
}