import { Injectable }         from '@angular/core';
import {
    BehaviorSubject,
    Observable
}                             from 'rxjs';

import { BaseService }        from '@Base/';
import {
    Messages,
    MessageProcessingService 
}                             from '@Messaging/';
import { Version }            from '@Utils/';


@Injectable({
    providedIn: 'root'
})
export class ClientConfigService extends BaseService
{
    public static readonly clientConfigDeviceMobilephonesOnly: string = 'devicemobilephonesonly';
    public static readonly clientConfigIcons:                  string = 'icons';
    public static readonly clientConfigRefresh:                string = 'refresh';
    public static readonly clientConfigWebTimeout:             string = 'webtimeout';

    private static readonly reloadInhibited:                   boolean = true; // [TBD]

    private _deviceMobilephonesOnly$:                          BehaviorSubject<boolean>;
    private _displayModeButtonEnabled$:                        BehaviorSubject<boolean>;
    private _icons$:                                           BehaviorSubject<any>;
    private _refreshButtonEnabled$:                            BehaviorSubject<boolean>;
    private _reloadRequired$:                                  BehaviorSubject<boolean>;
    private _version$:                                         BehaviorSubject<any>;
    private _webTimeout$:                                      BehaviorSubject<boolean>;

    private static readonly imageBase:                         string = "../../assets/images/organisation/";
    private static readonly locationAccuracyMin:               number = 500; // min location (GPS) accuracy in metres

    private static readonly sfn:                               string = "sfn";
    private static readonly penta:                             string = "penta";

    private static readonly company:                           string = ClientConfigService.sfn; // penta;

    // Always include SFN details - DON'T CHANGE
    private static readonly companyDetails:                    any    = {
        sfn: {
            email:       "",
            name:        "Spry Fox Networks",
            domain:      "spryfoxnetworks.com",
            emailPrefix: "contact",
            icon:        ClientConfigService.imageBase + ClientConfigService.sfn + ".png"
        },

        penta: {
            email:       "",
            name:        "Penta Consulting",
            domain:      "pentaconsulting.com",
            emailPrefix: "info",
            icon:        ClientConfigService.imageBase + ClientConfigService.penta + ".jpg"
        }
    }; // companyDetails

    // Fall-back to SFN if invalid company specified
    private cd: any;


    private readonly config =
    {
        // companyDetails:      this.cd,
        icons:               {},
        locationAccuracyMin: ClientConfigService.locationAccuracyMin,
        webTimeout:          true
    }; // config


    constructor(private readonly MessageProcessingService: MessageProcessingService)
    {
        super();

        this._deviceMobilephonesOnly$   = new BehaviorSubject<boolean>(false);     // live devices just show mobilephones
        this._displayModeButtonEnabled$ = new BehaviorSubject<boolean>(true);
        this._icons$                    = new BehaviorSubject<any>({});            // path to icons
        this._refreshButtonEnabled$     = new BehaviorSubject<boolean>(false);     // initially no refresh button required
        this._reloadRequired$           = new BehaviorSubject<boolean>(false);     // initially no update required
        this._version$                  = new BehaviorSubject<any>(Version.get());
        this._webTimeout$               = new BehaviorSubject<boolean>(true);      // initially webTimeout enabled
        
        // Listen for events - needed?
        const obs: Observable<any> | undefined = this.MessageProcessingService.getObs$(Messages.msgTypesInfo.clientconfig);
        if (obs instanceof Observable) this.sub = obs
            .subscribe((d: any): void => {
                this.update(d);
            }); // subscribe
    }


    //
    // Getters
    //
    get deviceMobilephonesOnly$(): Observable<boolean>
    {
        return this._deviceMobilephonesOnly$.asObservable();
    }


    get displayModeButtonEnabled$(): Observable<boolean>
    {
        return this._displayModeButtonEnabled$.asObservable();
    }



    get icons$(): Observable<Version>
    {
        return this._icons$.asObservable();
    }


    get refreshButtonEnabled$(): Observable<boolean>
    {
        return this._refreshButtonEnabled$.asObservable();
    }


    get reload$(): Observable<boolean>
    {
        return this._reloadRequired$.asObservable();
    }


    get version$(): Observable<Version>
    {
        return this._version$.asObservable();
    }


    get version(): any
    {
        return Version;
    }
    

    get webTimeoutEnabled$(): Observable<boolean>
    {
        return this._webTimeout$.asObservable();
    }


    //
    // Public methods
    //
    public getCompanyDetails(): object
    {
        // [TBD] Obtains this at browser start from server
        return this.cd;
    }


    public getLocationAccuracyMin(): number
    {
        return this.config ? this.config.locationAccuracyMin : 0;
    }


    public isCorrectVersion(vers: string): boolean
    {
        // Check if received version is same as currently running; if not, reload
        console.info("Running version: " + Version.getVersion() + ", target version: " + vers);
        return typeof vers === 'undefined' ? true : vers == Version.getVersion();
    }


    public update(d: any): void
    {
        if (d) {
            console.info("Client config msg received; updating local config");
            console.debug(d);

            if (! this.isCorrectVersion(d.targetVersion)) {
                console.info("Running software version is not the same as the target");
                if (! ClientConfigService.reloadInhibited) this._reloadRequired$.next(true);
            }

            Object.keys(Object(d)).forEach((v: string) : void => {
                if (v) switch (v.toLowerCase()) {
                    case ClientConfigService.clientConfigDeviceMobilephonesOnly:
                        this._deviceMobilephonesOnly$.next(Object(d)[v]);
                    break;

                    case ClientConfigService.clientConfigIcons:
                        this._icons$.next(Object(d)[v]);
                    break;

                    case ClientConfigService.clientConfigRefresh:
                        this._refreshButtonEnabled$.next(Object(d)[v]);
                    break;

                    case ClientConfigService.clientConfigWebTimeout:
                        this._webTimeout$.next(Object(d)[v]);
                    break;
                } // switch
            }); // forEach
        }
        else {
            console.warn("Client config msg received but no data");
        }
    }


    public webTimeout(): boolean
    {
        return this.config ? this.config.webTimeout : false;
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        if (this._deviceMobilephonesOnly$   instanceof BehaviorSubject) this._deviceMobilephonesOnly$.complete();
        if (this._displayModeButtonEnabled$ instanceof BehaviorSubject) this._displayModeButtonEnabled$.complete();
        if (this._icons$                    instanceof BehaviorSubject) this._icons$.complete();
        if (this._refreshButtonEnabled$     instanceof BehaviorSubject) this._refreshButtonEnabled$.complete();
        if (this._reloadRequired$           instanceof BehaviorSubject) this._reloadRequired$.complete();
        if (this._version$                  instanceof BehaviorSubject) this._version$.complete();
        if (this._webTimeout$               instanceof BehaviorSubject) this._webTimeout$.complete();
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising ClientConfig service");

        this.cd          = ClientConfigService.companyDetails[ClientConfigService.company]
                               ? ClientConfigService.companyDetails[ClientConfigService.company]
                               : ClientConfigService.companyDetails[ClientConfigService.sfn];
        this.cd.email    = this.cd.emailPrefix + '@' + this.cd.domain;
        this.cd.emailUrl = 'mailto:'                 + this.cd.email;
        this.cd.web      = 'www.'                    + this.cd.domain;
        this.cd.webUrl   = 'https://'                + this.cd.web;
    }
}