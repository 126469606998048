import { Component }     from '@angular/core';
import { RouterModule }  from '@angular/router';

import { BaseComponent } from '@Base/';


@Component({
   selector:    'qp-sidebar-container',
   templateUrl: 'sidebar-container.component.html',
   styleUrls:   [
      'sidebar-container.component.css'
   ],
   imports:     [
      RouterModule
   ]
})
export class SideBarContainerComponent extends BaseComponent
{
   
}