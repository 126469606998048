import { CommonModule }         from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    Output
}                               from '@angular/core';
import { FlexLayoutModule }     from '@angular/flex-layout';

import { BaseComponent }        from '@Base/';
import { MaterialCustomModule } from '@Material/';


export type TableDataValue = {
    /**
     * Key
     */
    k: any;

    /**
     * Value
     */
    v: any;

    /**
     * Class
     */
    c?: any;
}; // TableDataValue
export const isTableDataValue = (x: any): x is TableDataValue => x.hasOwnProperty('k') && x.hasOwnProperty('v');


@Component({
    selector:    'qp-table-data',
    templateUrl: 'table-data.component.html',
    styleUrls:   [
        'table-data.component.css'
    ],
    imports:     [
        CommonModule,
        FlexLayoutModule,
        MaterialCustomModule
    ]
})
export class TableDataComponent extends BaseComponent
{
    public readonly spaceKey:   string                                     = "35%";
    public readonly spaceValue: string                                     = "65%";


    private         _data:      (TableDataValue | undefined)[] | undefined = undefined;


    @Input()
    private         nullValues: boolean                                    = true;


    @Output()
    public readonly selected:   EventEmitter<TableDataValue >              = new EventEmitter();


    // Getters and setters
    @Input()
    public get data(): (TableDataValue | undefined)[] | undefined
    {
        return Array.isArray(this._data)
            ? this._data.filter(d => d && d.k && (this.nullValues || d.v))
            : this._data;
    }


    public set data(d: (TableDataValue | undefined)[] | undefined)
    {
        this._data = d;
    }


    public select(row: TableDataValue): void
    {
        this.selected.emit(row);
    }
}