import {
    Pipe,
    PipeTransform
}                 from '@angular/core';

import { Dateq }  from '@Utils/';


@Pipe({
    name: 'datetime',
    pure: false,
})
export class DateTimePipe implements PipeTransform
{
    transform(d: Date): string | undefined
    {
        return (d instanceof Date) ? (d as Dateq).displayStr : undefined;
    }
}