import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';

import {
    Observable,
    Subscription,

    timer
}                                  from 'rxjs';

import { IconsMaterial }           from '@Icons/';
import { MaterialCustomModule }    from '@Material/';
import { DataServiceEvents }       from '@Misc/Services/';
import { DeviceCollectionService } from '@Devices/devices-active.service';

import { SideBarBaseComponent }    from './sidebar-base.component';


@Component({
    selector:    'qp-sidebar-devices',
    templateUrl: 'sidebar-devices.component.html',
    styles:      [
        'sidebar-devices.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class SideBarDevicesComponent extends SideBarBaseComponent
{
    // private static readonly timeout: number                    = 5; // secs

    // public                  colour:  any                       = SideBarBaseComponent.colourPrimary;
    // // public                  num:     number                    = 0;

    // private                 _timer:  Subscription | undefined  = undefined;


    constructor(private readonly DeviceCollectionService: DeviceCollectionService)
    {
        super(IconsMaterial.devices, DeviceCollectionService, false);
    }


    //
    // // Getters and setters
    // //
    // protected override set numI(d: number)
    // {
    //     if (this.num != d) {
    //         super.numI = d;
    //         this.colour = SideBarBaseComponent.colourAccent;

    //         // Modify badge colour to indicate device number changed
    //         this.timerS = timer(SideBarDevicesComponent.timeout * 1000)
    //             .subscribe(() => {
    //                 this.colour = SideBarBaseComponent.colourPrimary;
    //                 this.timerS = undefined;
    //             }); // subscribe
    //     }
    // }

    // private get timerS(): Subscription | undefined
    // {
    //     return this._timer;
    // }

    // private set timerS(t: Subscription | undefined)
    // {
    //     this._clearTimer();
    //     this._timer = t;
    // }



    //
    // Protected methods
    //

    // Override
    // protected override cleanUp(): void
    // {
    //     super.cleanUp()

    //     this._clearTimer();
    // }


    // // Override
    // protected override initialise(): void
    // {
    //     super.initialise();

    //     // const obs: Observable<any> | undefined = this.DeviceCollectionService[DataServiceEvents.number];
    //     const obs: Observable<any> | undefined = this.DevicesService[DataServiceEvents.number];
    //     if (obs instanceof Observable) this.sub = obs.subscribe((d: number): void => {
    //         if (this.num !== d) {
    //             this.num = d;
    //             this.colour = SideBarBaseComponent.colourAccent;

    //             // Modify badge colour to indicate device number changed
    //             this.timerS = timer(SideBarDevicesComponent.timeout * 1000)
    //                 .subscribe(() => {
    //                     this.colour = SideBarBaseComponent.colourPrimary;
    //                     this.timerS = undefined;
    //                 }); // subscribe
    //         }
    //     }); // subscribe
    // }



    // //
    // // Private methods
    // //
    // private _clearTimer(timer: Subscription | undefined = this._timer): void
    // {
    //     if (timer instanceof Subscription && ! timer.closed) timer.unsubscribe();
    //     timer = undefined;
    // }
}