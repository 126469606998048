import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';


import { MaterialCustomModule } from '@Material/';
import { IconsMaterial }        from '@Icons/';
import { WifiApsService }       from '@Wifi/wifiaps.service';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
    selector:    'qp-sidebar-wifiaps',
    templateUrl: 'sidebar-wifiaps.component.html',
    styles:      [
        'sidebar-wifiaps.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class SideBarWifiApsComponent extends SideBarBaseComponent
{
    constructor(WifiApsService: WifiApsService)
    {
        super(IconsMaterial.wifi, WifiApsService);
    }
}