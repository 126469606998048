import {
   Component,
   Input
}                              from '@angular/core';

import { IconsMaterial }       from '@Icons/';
import { Image }               from '@ObjElements/image/';

import { ButtonBaseComponent } from './button-base.component';
    


@Component({
   selector:     'qp-image-list',
   templateUrl:  'image-list.component.html',
   styleUrls:   ['image-list.component.css'],
   standalone: false
})
export class ImageListComponent extends ButtonBaseComponent
{
   @Input()
   public images: Image[];
}