import { CommonModule }              from '@angular/common';
import { Component }                 from '@angular/core';
import { MediaObserver }             from '@angular/flex-layout';
import {
    ActivatedRoute,
    Router 
}                                    from '@angular/router';

import { ElementInfoComponent }      from '../../element/element/element-info.component';

import { TableDataComponent }        from '@GuiElements/table-data.component';

import { MaterialCustomModule }      from '@Material/';

import { MobileCellInfoComponent }   from '@ObjElements/mobile-cells/mobile-cell/mobile-cell-info.component';
import { NotificationInfoComponent } from '@ObjElements/notification/';

import { DeviceInfoComponent }       from '../device/';
import { DeviceRepeater }            from './device-repeater.class';


@Component({
    selector:    'qp-device-repeater-info',
    templateUrl: 'device-repeater-info.component.html',
    styleUrls:   [
        'device-repeater-info.component.css'
    ],
    imports:     [
        DeviceInfoComponent,
        MobileCellInfoComponent,
        NotificationInfoComponent,
        TableDataComponent,

        CommonModule,
        MaterialCustomModule
    ]
})
export class DeviceRepeaterInfoComponent extends ElementInfoComponent
{
    // public readonly cellAttrs: string[] = [
    //     'id',
    //     'technology',
    //     'frequencyband',
    //     'dlfrequency',
    //     'mcc',
    //     'mnc',
    //     'cellid',
    //     'cellcode',
    //     'signalstrength',
    //     'signalquality'
    // ]; // cellAttrs


    constructor(                ActRoute: ActivatedRoute,
                                Router:   Router,
                
                public readonly Media:    MediaObserver)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): DeviceRepeater
    {
        return this.data as DeviceRepeater;
    }


    public selectedFn(ev: any): void
    {
        console.debug("[TBD]");
    }
}