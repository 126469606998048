@if (d) {
    <qp-table-data
        [nullValues]="nullValues"
        [data]="
            [
                { k: 'Rnc Id',        v: d.rcid                        },
                { k: 'Local cell Id', v: d.lcid                        },
                { k: 'Psc',           v: d.psc                         },
                { k: 'Lac',           v: d.lac                         },
                { k: 'Rscp',          v: d.rscp ? d.rscp + ' dBm' : '' },
                { k: 'Ecno',          v: d.ecno ? d.ecno + ' dB' : ''  }
            ]">
    </qp-table-data>
}