import {
    Component,
    Input
}                              from '@angular/core';

import {
    MobileCell,
    MobileCellLevelComponent
}                              from '../mobile-cell/';

import { MobileCellLteCommon } from './'


@Component({
    selector:    'qp-mobile-cell-lte-level',
    templateUrl: 'mobile-cell-lte-level.component.html',
    styleUrls:   [
        'mobile-cell-lte-level.component.css'
    ],
    imports:     [
    ]
})
export class MobileCellLteLevelComponent extends MobileCellLevelComponent
{
    //
    // Getters
    //

    public static override getLevelToolTip(d: MobileCell): string
    {
        return super.getLevelToolTip(d)
            + (d instanceof MobileCellLteCommon
                ? (d.rsrp ? "\nRsrp: " + d.rsrp : "")
                    + (d.rsrq  ? "\nRsrq: "  + d.rsrq   : "")
                    + (d.rssnr ? "\nRssnr: " + d.rssnr  : "")
                
                : ""
            );
    }
}