<!-- <div class="buttonAndTextOuter"> -->
<!-- <div> -->
    @if (rtBack && routeBack) {
        <qp-route-back
            [route]="routeBack"
        >
        </qp-route-back>
    }

    <div class="buttonAndTextInner">
        @if (refreshEnabled) {
            <qp-refresh-button
                (pressed)="refreshFn()"
            >
            </qp-refresh-button>
        }

        @if (statusEnabled) {
            <qp-status-indicator
                [state]=state
                [icon]="icon"
            >
            </qp-status-indicator>
        }
    </div>

    @if (title) {
        <div class="buttonAndTextInner buttonAndTextInner3">
            <qp-window-title
                [title]="title"
                [value]="value"
            >
            </qp-window-title>
        </div>
    }

    <!-- <div class="buttonAndTextInner"> -->
    @if (geolocationEnabled) {
        <div>
            <qp-geolocation-button
                (pressed)="geolocationFn()"
            >
            </qp-geolocation-button>
        </div>
    }
<!-- </div> -->
 <!-- buttonAndTextOuter -->