import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../../element/element/element-info.component';
import { MaterialCustomModule } from '@Material/';

import { DeviceInfoComponent }  from '../device/';
import { DeviceBeacon }         from './device-beacon.class';


@Component({
    selector:    'qp-device-beacon-info',
    templateUrl: 'device-beacon-info.component.html',
    styleUrls:   [
        'device-beacon-info.component.css'
    ],
    imports:     [
        DeviceInfoComponent,
        
        CommonModule,
        MaterialCustomModule
    ]
})
export class DeviceBeaconInfoComponent extends ElementInfoComponent
{
    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }

    
    //
    // Getters
    //
    public get d(): DeviceBeacon
    {
        return this.data as DeviceBeacon;
    }
}