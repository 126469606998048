import { Component }               from '@angular/core';
import { MatDialogRef }            from '@angular/material/dialog';
import { MatRow }                  from '@angular/material/table';
import { Router }                  from '@angular/router';

import {
    DialogComponent,
    ListComponent
}                                  from '@Misc/Components';//../Misc/dialog.component';//@Misc/';
import {
    DataServiceI,
    ProgressSpinnerDialogService
}                                  from '@Misc/Services';
import {
    Device,
    ObjectElementsComponent
}                                  from '@ObjElements/';

import { routeNames }              from '../app.routing.names';

import { DeviceCollectionService } from './devices-active.service';


@Component({
   selector:     'qp-devices-list',
   templateUrl:  'devices-active.component.html',
   styleUrls:   ['../Misc/Components/list.component.css'],
   imports:     [
       ListComponent
   ]
})
// export class DevicesListComponent extends DialogComponent
export class DevicesListComponent extends ObjectElementsComponent
{
    // public readonly title: string    = "Devices"; 

    public readonly attrs: string[]  = [
        'id',
        'description',
        // 'location',
        'manufacturer',
        'model',
        'type',
        // 'status'
    ]; // attrs


    public constructor(                 DeviceCollectionService:      DeviceCollectionService,
                                        ProgressSpinnerDialogService: ProgressSpinnerDialogService,

                       private readonly Router:                       Router)
                                        // DialogRef:               MatDialogRef<DialogComponent>)
    {
        super(DeviceCollectionService, ProgressSpinnerDialogService);
    }


    //
    // Public methods
    //

    // Override
    public override select(row: MatRow): Promise<boolean> | undefined
    {
        super.select(row);

        return (row instanceof Device && (row as Device).id)
            ? this.Router.navigate([ routeNames.devices, (row as Device).id ])
            : undefined;
    }
}