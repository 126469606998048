import {
    Component,
    Input
}                                  from '@angular/core';

import { ElementInfoComponent }    from '../../element/element/element-info.component';
import { TableDataComponent }      from '@GuiElements/table-data.component';

import { MobileCellTdscdmaCommon } from './';


@Component({
    selector:    'qp-mobile-cell-tdscdma-info',
    templateUrl: 'mobile-cell-tdscdma-info.component.html',
    styleUrls:   [
        'mobile-cell-tdscdma-info.component.css'
    ],
    imports:     [
        TableDataComponent
    ]
})
export class MobileCellTdscdmaInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:       MobileCellTdscdmaCommon;

    @Input()
    public nullValues: boolean                  = true;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }
}