import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';
import { OrganisationsService } from '@Organisations/organisations.service';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
    selector:    'qp-sidebar-organisations',
    templateUrl: 'sidebar-organisations.component.html',
    styles:      [
        'sidebar-organisations.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class SideBarOrganisationsComponent extends SideBarBaseComponent
{
    constructor(OrganisationsService: OrganisationsService)
    {
        super(IconsMaterial.organisations, OrganisationsService);
    }
}