import { CommonModule }                       from '@angular/common';
import {
    Component,
    Input
}                                             from '@angular/core';

import { NotificationIconState }              from '@GuiElements/';
import { NotificationIconComponent }          from '@GuiElements/notification-icon.component';
import { TableDataComponent }                 from '@GuiElements/table-data.component';
import { Notification as NotificationCommon } from './'
// import { NotificationCommon }    from './@Common/Elements/Notification/'

import { ElementInfoComponent }               from '../element/element/element-info.component';
import { MaterialCustomModule }               from '@Material/';


@Component({
    selector:    'qp-notification-info',
    templateUrl: 'notification-info.component.html',
    styleUrls:   [
        'notification-info.component.css'
    ],
    imports:     [
        NotificationIconComponent,

        CommonModule,
        MaterialCustomModule,
        TableDataComponent

    ]
})
export class NotificationInfoComponent extends ElementInfoComponent
{
    @Input()
    public nullValues: boolean = true;


    //
    // Getters
    //
    public get d(): NotificationCommon
    {
        return this.data as NotificationCommon;
    }


    public get state(): NotificationIconState
    {
        return this.d instanceof NotificationCommon ? this.d.severity : undefined;
    }
}