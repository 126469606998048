import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';
import { Utils }                from '@Utils/';

import { MobileCell }           from './';


@Component({
    selector:     'qp-mobile-cell-level',
    templateUrl:  'mobile-cell-level.component.html',
    styleUrls:   [
        'mobile-cell-level.component.css'
    ],
    imports:     [
    ]
})
export class MobileCellLevelComponent extends ElementInfoComponent
{
    //
    // Getters
    //

    public static getLevelToolTip(d: MobileCell): string
    {
        return (d instanceof MobileCell)
            ? ((d.simslot ? "Sim " + d.simslot + ": " : "")
                + Utils.stringCapFirstLetter(d.operatorstr)
                + "\nTechnology: " + d.technologystr
            )
            : "Technology: unknown";
    }
}