import {
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    Output
}                               from '@angular/core';
import {
    ActivatedRoute,
    RouterModule
}                               from '@angular/router';
import { Observable }           from 'rxjs';

import { BaseComponent }        from '@Base/';
import { HeaderService }        from '@Header/';
import {
    MessageInterface,
    MessageProcessingService,
    MessageService
}                               from '@Messaging/';
import { TabsService }          from '@Navigation/';
import { GeoLocationService }   from '@Misc/Services/';

import { MainDesktopComponent } from './desktop/';


@Component({
    selector:    'qp-main',
    templateUrl: 'main.component.html',
    styleUrls:  [
        'main.component.css'
    ],
    imports:    [
        RouterModule
    ]
})
export class MainComponent extends BaseComponent
{
    @Output()
    private selected:       EventEmitter<boolean> = new EventEmitter();

    private routeComponent: ComponentRef<any>;


    constructor(private readonly ActRoute:                 ActivatedRoute,
        
                // Keep here as this casues service to start and send geolocation to server
                private readonly GeoLocationService:       GeoLocationService,
                private readonly HeaderService:            HeaderService,
                private readonly MessageService:           MessageService,
                private readonly MessageProcessingService: MessageProcessingService,
                private readonly TabsService:              TabsService)
    {
        super();
    }


    //
    // Public methods
    //
    public onClick(event: any): void
    {
        this.selected.emit(event);
        if (this.routeComponent instanceof MainDesktopComponent) (this.routeComponent as MainDesktopComponent).onClick(event);
    }


    public onRouteActivated(componentRef: ComponentRef<any>): void
    {
        this.routeComponent = componentRef;
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Main component");

        if (this.HeaderService) this.HeaderService.all = true; // enable all buttons on header bar


        // Receive messages, and send to messageProcessing
        const obs: Observable<MessageInterface> | undefined = this.MessageService.messageEvent$;
        if (obs instanceof Observable) this.sub = obs
            .subscribe((d: MessageInterface): void => {
                if (d) this.MessageProcessingService.process(d);
            }); // subscribe

        if (this.ActRoute instanceof ActivatedRoute) {
            if (this.ActRoute.url instanceof Observable) this.sub = this.ActRoute.url
                .subscribe((d: any): void => {
                    if (Array.isArray(d)) {
                        //console.log(d);
                        if (d[0]) console.info(d[0].path);
                    }
                }); // subscribe

            if (this.ActRoute.data instanceof Observable) this.sub = this.ActRoute.data
                .subscribe((d: any): void => {
                    console.info(this.ActRoute);
                    if (Object(d) && Object.keys(Object(d)).length > 0) {
                        console.info("External data: " + JSON.stringify(d));
                        this.TabsService.tabActiveReq = d.id;
                    }
                }); // subscribe
        }
    }
}