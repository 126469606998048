import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';
import { MobileCellsService }   from '@MobileCells/mobile-cells.service';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
    selector:    'qp-sidebar-mobilecells',
    templateUrl: 'sidebar-mobilecells.component.html',
    styles:      [
        'sidebar-mobilecells.component.css'
    ],
    imports:     [
        CommonModule,
        MaterialCustomModule
    ]
})
export class SideBarMobileCellsComponent extends SideBarBaseComponent
{
    constructor(MobileCellsService: MobileCellsService)
    {
        super(IconsMaterial.mobile_cells, MobileCellsService);
    }
}