'use strict'


import {
    AttributesHelperCommon,
    DeviceHelper
}                                 from './Devices/index.mjs';
// import { ElementMainCommon }      from './ElementMain.class.mjs';
import { DeviceGroup }            from './DeviceGroup/index.mjs';
import { Image }                  from './Image/index.mjs';
import { KpiHelper }              from './Kpi/index.mjs';
import { MobileCellHelperCommon } from './MobileCell/index.mjs';
import { MobileNetworkCommon }    from './MobileNetwork/index.mjs';
import { NotificationHelper }     from './Notification/index.mjs';
import { Organisation }           from './Organisation/index.mjs';
import { Site }                   from './Site/index.mjs';
import { Survey }                 from './Survey/index.mjs';
import { 
    TestHelper,
    TestProfile
}                                 from './Tests/index.mjs';
import { User }                   from './User/index.mjs';
import { WifiCellHelper }         from './WifiCell/index.mjs';

import { ElementType }            from './ElementType.class.mjs';


export class ElementHelperCommon
{
    // Factory method
    static get(d, type, attrs, field, ignoreEmpty)
    {
        if (! type) {
            console.info("ElementHelperCommon - no type given: " + type);
            return undefined;
        }
        else if (ElementHelperCommon._isEmpty(d) && ! ignoreEmpty) {
            // console.info("ElementHelperCommon - no data given: " + JSON.stringify(d));
            return undefined;
        }
        // if ((ElementHelperCommon._isEmpty(d) && ! ignoreEmpty) || ! type) return undefined;

        // if (! d || ! type && ! type in ElementHelperCommon.fieldsA) return undefined;
        let attr = ElementHelperCommon._getAttr(d, field ? field : type);
        if (! ignoreEmpty) attr = ! ElementHelperCommon._isEmpty(attr) ? attr : d;

        let fn = undefined;
        switch (type) {
            case ElementType.Device:
            case DeviceHelper.field:
                fn = DeviceHelper.get;
            break;

            case DeviceGroup.field:
                fn = DeviceGroup.get;
            break;

            // case ElementMainCommon.field:
            //     fn = ElementMainCommon.get;
            //     break;

            case ElementType.Image:
                fn = Image.get;
            break;

            case ElementType.Kpi:
                fn = KpiHelper.get;
            break;

            case ElementType.Mobilecell:
            case MobileCellHelperCommon.field:
                fn = MobileCellHelperCommon.get;
            break;

            case ElementType.Mobilenetwork:
            case MobileNetworkCommon.field:
                fn = MobileNetworkCommon.get;
            break;

            case ElementType.Notification:
            case NotificationHelper.field:
                fn = NotificationHelper.get;
                break;

            case ElementType.Organisation:
            case Organisation.field:
                fn = Organisation.get;
            break;

            case ElementType.Site:
            case Site.field:
                fn = Site.get;
            break;

            case ElementType.Survey:
            case Survey.field:
                fn = Survey.get;
            break;
            
            case ElementType.Test:
            case TestHelper.field:
                fn = TestHelper.get;
            break;

            case ElementType.TestProfile:
                fn = TestProfile.get;
            break;

            case ElementType.User:
                fn = User.get;
            break;

            case ElementType.Wificell:
            case WifiCellHelper.field:
                fn = WifiCellHelper.get;
            break;

            default:
                // Must return here as args different to other cases
                return AttributesHelperCommon.get(attr, type, attrs);
        } // switch

        return (fn instanceof Function)
            ? fn(attr, attrs)
            : undefined;
    }


    //
    // Protected methods
    //
    static convertArray(d, a, t)
    {
        let os = [];

        if (d && a) os = ElementHelperCommon._convertArray(
            ElementHelperCommon._getAttr(d, a),
            t
        );

        return os;
    }


    static convertArray2(a, fn)
    {
        let os = undefined;

        if (fn instanceof Function) {
            if (Array.isArray(a)) {
                os = [];
                a.forEach((o) => {
                    const o2 = fn(o);
                    if (o2) os.push(o2);
                }); // forEach
            }
            else {
                os = fn(a);
            }
        }

        return os;
    }


    //
    // Private methods
    //
    static _convertArray(d, t)
    {
        const os = [];

        if (Array.isArray(d)) d.forEach((o) => {
            const o2 = ElementHelperCommon.get(o, t);
            if (o2) os.push(o2);
        }); // forEach

        return os;
    }

    
    static _getAttr(d, a)
    {
        return d && a && a in Object(d) ? d[a] : undefined;
    }


    static _isEmpty(d)
    {
        if (d) for (const k in d) {
            if (d.hasOwnProperty(k)) return false;
        } // for

        return true;
    }
}