import { Component }     from '@angular/core';
import { RouterModule }  from '@angular/router';

import { BaseComponent } from '@Base/';



@Component({
    selector:     'qp-tabs-container',
    templateUrl:  'tabs-container.component.html',
    styleUrls:   ['tabs-container.component.css'],
    imports:     [
        RouterModule
    ]
})
export class TabsContainerComponent extends BaseComponent
{
}