import { NgModule }                 from '@angular/core';

// import { BrowserAnimationsModule }  from '@angular/platform-browser/animations';
import {
    FormsModule,
    ReactiveFormsModule
}                                   from '@angular/forms'; // allows ngModel to work

// import { MatAutocompleteModule }    from '@angular/material/autocomplete';

import { MatBadgeModule }           from '@angular/material/badge';
export {
    type MatBadgePosition,
    type MatBadgeSize
}                                   from '@angular/material/badge';

import { MatBottomSheetModule }     from '@angular/material/bottom-sheet';
// import { MatButtonToggleModule }    from '@angular/material/button-toggle';
// import { MatChipsModule }           from '@angular/material/chips';

import { MatRippleModule }          from '@angular/material/core';
export { type ThemePalette }        from '@angular/material/core';

// import { MatDatepickerModule }      from '@angular/material/datepicker';
import { MatExpansionModule }       from '@angular/material/expansion';
// import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatGridListModule }        from '@angular/material/grid-list';
import { MatIconModule }            from '@angular/material/icon';

// import { MatProgressBarModule }     from '@angular/material/progress-bar';
// import { MatRadioModule }           from '@angular/material/radio';

import { MatSidenavModule }         from '@angular/material/sidenav';
export {
    type MatDrawerMode,
    MatSidenav
}                                   from '@angular/material/sidenav';

// import { MatSliderModule }          from '@angular/material/slider';
// import { MatSlideToggleModule }     from '@angular/material/slide-toggle';

import { MatSortModule }            from '@angular/material/sort';
export {
    MatSort,
    type Sort,
    type SortDirection
}                                   from '@angular/material/sort';


// import { MatStepperModule }         from '@angular/material/stepper';
import { MatToolbarModule }         from '@angular/material/toolbar';


// Pre Angular 15
import { MatButtonModule }          from '@angular/material/button';
import { MatCardModule }            from '@angular/material/card';

import { MatCheckboxModule }        from '@angular/material/checkbox';
export { MatCheckboxChange }        from '@angular/material/checkbox';

import { MatDialogModule }          from '@angular/material/dialog';
export { MatDialogRef }             from '@angular/material/dialog';

import { MatInputModule }           from '@angular/material/input';
import { MatListModule }            from '@angular/material/list';
import { MatMenuModule }            from '@angular/material/menu';

import { MatPaginatorModule }       from '@angular/material/paginator';
export { 
    MatPaginator,
    PageEvent
}                                   from '@angular/material/paginator';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
export {
    MatProgressSpinnerModule,
    type ProgressSpinnerMode
}                                   from '@angular/material/progress-spinner';

import { MatSelectModule }          from '@angular/material/select';
export { 
    MatSelect,
    MatSelectChange
    // MatOptionSelectionChange
}                                   from '@angular/material/select';

import { MatSnackBarModule }        from '@angular/material/snack-bar';
export {
    MatSnackBar,
    MatSnackBarConfig,
    type MatSnackBarDismiss,
    MatSnackBarRef,
    type TextOnlySnackBar
}                                   from '@angular/material/snack-bar';

import { MatTableModule }           from '@angular/material/table';
export {
    MatRow,
    MatTableDataSource
}                                   from '@angular/material/table';

import { MatTabsModule }            from '@angular/material/tabs';
import { MatTooltipModule }         from '@angular/material/tooltip';


// Post Angular 15 - legacy - for A17+, need to use:
// "@angular/cdk": "^16.2.13",
// "@angular/material": "^16.2.13",


// import { MatLegacyButtonModule          as MatButtonModule }          from '@angular/material/legacy-button';
// import { MatLegacyCardModule            as MatCardModule }            from '@angular/material/legacy-card';

// import { MatLegacyCheckboxModule as MatCheckboxModule }               from '@angular/material/legacy-checkbox';
// export { MatLegacyCheckboxChange as MatCheckboxChange }               from '@angular/material/legacy-checkbox';

// import { MatLegacyDialogModule          as MatDialogModule }          from '@angular/material/legacy-dialog';
// import { MatLegacyInputModule           as MatInputModule }           from '@angular/material/legacy-input';
// import { MatLegacyListModule            as MatListModule }            from '@angular/material/legacy-list';
// import { MatLegacyMenuModule            as MatMenuModule }            from '@angular/material/legacy-menu';

// import { MatLegacyPaginatorModule       as MatPaginatorModule }       from '@angular/material/legacy-paginator';
// import { PageEvent}                                                   from '@angular/material/legacy-paginator';

// import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

// import { MatLegacySelectModule          as MatSelectModule }          from '@angular/material/legacy-select';
// export { MatLegacySelectChange          as MatSelectChange }          from '@angular/material/legacy-select';

// import { MatLegacySnackBarModule  as MatSnackBarModule }              from '@angular/material/legacy-snack-bar';
// export {
//     MatLegacySnackBar        as MatSnackBar,
//     MatLegacySnackBarConfig  as MatSnackBarConfig,
//     MatLegacySnackBarDismiss as MatSnackBarDismiss,
//     MatLegacySnackBarRef     as MatSnackBarRef,
//     LegacyTextOnlySnackBar   as TextOnlySnackBar
// }                                                                     from '@angular/material/legacy-snack-bar';

// import { MatLegacyTableModule           as MatTableModule }           from '@angular/material/legacy-table';
// export { MatRow }                                                     from '@angular/material/legacy-table';

// import { MatLegacyTabsModule            as MatTabsModule }            from '@angular/material/legacy-tabs';
// import { MatLegacyTooltipModule         as MatTooltipModule }         from '@angular/material/legacy-tooltip';

import { CdkTableModule } from '@angular/cdk/table';
const mods: any[] = [
        // BrowserAnimationsModule, // Do in top-level module only
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        //MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        //MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        //MatChipsModule,
        //MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        //MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        //MatProgressBarModule,
        MatProgressSpinnerModule,
        //MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        //MatSliderModule,
        //MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        //MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule
]; // mods


@NgModule({
    imports: mods,
    exports: mods
})
export class MaterialCustomModule { }