import {
    Component,
    Input
}                        from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition,
 }                       from '@angular/animations';

import { BaseComponent } from '@Base/';
import { ElementState }  from '@ObjElements/element/element/';
// import { IconsMaterial } from '@Icons/';


// Values here need to match associated CSS
export enum StatusIndicatorState {
    Good    = 'good',
    Average = 'average',
    Poor    = 'poor',
    None    = 'none',
    Unknown = 'unknown'
}; // StatusIndicatorState


@Component({
    selector:     'qp-status-indicator',
    templateUrl:  'status-indicator.component.html',
    styleUrls:   ['status-indicator.component.css'],
    standalone: false,
    animations: [
        trigger('openClose', [
            state('open', style({
                height: '200px',
                opacity: 1,
                backgroundColor: 'yellow'
            })),
            state('closed', style({
                height: '100px',
                opacity: 0.8,
                backgroundColor: 'blue'
            })),
            transition('open => closed', [
                animate('1s')
            ]),

            transition('closed => open', [
                animate('0.5s')
            ]),

            transition('* => closed', [
                animate('1s')
            ]),

            transition('* => open', [
                animate('0.5s')
            ]),

            transition('open <=> closed', [
                animate('0.5s')
            ]),

            transition ('* => open', [
                animate('1s', style ({ opacity: '*' }) )
            ]),

            transition('* => *', [
                animate('1s')
            ])
        ])
    ]
})
export class StatusIndicatorComponent extends BaseComponent
{
    @Input()
    public icon: string;//                      = IconsMaterial.status;

    
    private      _state: StatusIndicatorState = StatusIndicatorState.None;


    public get stateI(): StatusIndicatorState
    {
        return this._state;
    }

    @Input()
    public set state(d: StatusIndicatorState | ElementState)
    {
        if (d) this._state = (d instanceof ElementState) ? StatusIndicatorComponent.get(d) : d;
    }


    public get StatusIndicatorState(): typeof StatusIndicatorState
    {
        return StatusIndicatorState;
    }
    
    
    // Allow setting by ElementState
    // @Input()
    // public set stateE(d: ElementState)
    // {
    //     this._state = StatusIndicatorComponent.getState(d);
    // }
    

    // Values here need to match associated CSS
    // public static readonly states = {
    //     good:    'good',
    //     average: 'average',
    //     poor:    'poor',
    //     none:    'none',
    //     unknown: 'unknown'
    // };


    private static get(d: ElementState | undefined): StatusIndicatorState
    {
        if (d instanceof ElementState) switch (d) {
            case ElementState.Good:    return StatusIndicatorState.Good;
            case ElementState.Average: return StatusIndicatorState.Average;
            case ElementState.Poor:    return StatusIndicatorState.Poor;
            case ElementState.None:    return StatusIndicatorState.None;

            case ElementState.Unknown:
            default:
                // Fall through
        } // switch

        return StatusIndicatorState.Unknown;
    }
}