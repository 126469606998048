import { NgModule }           from '@angular/core';
import {
    ExtraOptions,
    NoPreloading,
    PreloadAllModules,
    PreloadingStrategy,
    Route,
    Routes,
    RouterModule
}                             from '@angular/router';
import {
    Observable,
    
    of
 }                            from 'rxjs';

import {
    AuthenticationGuard,
    LoginComponent,
    SecureAuthenticationGuard,
    SecureLoginComponent
}                             from '@Login/';

import { routeNames }         from './app.routing.names';


export class AppCustomPreloader implements PreloadingStrategy
{
    preload(route: Route, load: Function): Observable<any> {
        return route.data && route.data.preload ? load() : of(null);
    }
}


export const routerConfig: ExtraOptions = {
    enableTracing:         true,                 // false
    initialNavigation:     'enabledNonBlocking',
    onSameUrlNavigation:   'ignore',             // 'reload'
    preloadingStrategy:    AppCustomPreloader,//NoPreloading,//PreloadAllModules,    // used for lazy loading
 
    bindToComponentInputs: true
    // useHash:             true
};

// Need A2 component (downgraded and on index.html so AJS knows what to do), which includes router-outlet

export const routes: Routes = [
    {
        path:         routeNames.login,
        component:    LoginComponent
    },

    // Used for Nef location demo
    {
        path:         routeNames.securelogin,
        component:    SecureLoginComponent,
        canActivate:  [ SecureAuthenticationGuard ]
    },

    { 
        path:         '',
        // component:    MainComponent,
        canActivate:  [ AuthenticationGuard ],
        loadChildren: async () => (await import('@Main/main.routes')).mainRoutes, // better - needs Ivy
        // loadChildren: async () => (await import('@Main/main.module')).MainModule, // better - needs Ivy
        data: { preload: true }
        // loadChildren: () => import('@Main/main.module').then(m => m.MainModule),
        // children:     sidebarRoutes //tabRoutes
    },

    // Finally redirect to home
    {
        path:         '**',
        redirectTo:   ''
    }
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes, routerConfig)
    ],

    providers: [
        AppCustomPreloader
    ],

    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }