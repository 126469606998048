import { CommonModule }                   from '@angular/common';
import { Component }                      from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                                         from '@angular/router';

import { ElementInfoComponent }           from '../element/element/element-info.component';

import { 
    Device, 
    DeviceTypes
}                                         from './device/';
import { DeviceBeaconInfoComponent }      from './device-beacon';
import { DeviceMobilePhoneInfoComponent } from './device-mobilephone';
import { DeviceRepeaterInfoComponent }    from './device-repeater';


@Component({
    selector:    'qp-device-info-container',
    templateUrl: 'device-info-container.component.html',
    styleUrls:   [
        'device-info-container.component.css'
    ],
    imports:     [
        DeviceBeaconInfoComponent,
        DeviceMobilePhoneInfoComponent,
        DeviceRepeaterInfoComponent,

        CommonModule
    ]
})
export class DeviceInfoContainerComponent extends ElementInfoComponent
{
    public readonly DeviceTypes: any = DeviceTypes;


    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): Device
    {
        return this.data as Device;
    }
}