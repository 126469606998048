import {
    Component,
    Input,
}                               from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';
import {
    Connection,
    ConnectionTransport
}                               from '@ObjElements/devices/attributes/connection/';

import { ButtonBaseComponent } from './button-base.component';


@Component({
    selector:    'qp-connection-icon',
    templateUrl: 'connection-icon.component.html',
    styleUrls:   [
       'connection-icon.component.css'
    ],
    imports:     [
        MaterialCustomModule
    ]
})
export class ConnectionIconComponent extends ButtonBaseComponent
{
    private _icon: string = "";
    private _type: string = "";


    @Input()
    public  connection: Connection;


    //
    // Getters and setters
    //
    @Input()
    public get icon(): string
    {
        return (this._icon)
            ? this._icon
            : ConnectionIconComponent.getIcon(this.type); // getIcon
    }

    public set icon(d)
    {
        this._icon = d;
    }


    @Input()
    public get type(): string
    {
        return (this._type)
            ? this._type
            : ((this.connection instanceof Connection)
                ? this.connection.type?.type
                : this._type
            );
    }

    public set type(d: string)
    {
        this._type = d;
    }


    //
    // Public methods
    //
    private static getIcon(d: string | Connection | ConnectionTransport): string
    {
        switch ((d instanceof Connection) ? d.type : (d instanceof ConnectionTransport) ? d : ConnectionTransport.get(d)) {
            case ConnectionTransport.Bluetooth: return IconsMaterial.connection_bluetooth;
            case ConnectionTransport.Cellular:  return IconsMaterial.connection_mobilecell;
            case ConnectionTransport.Ethernet:  return IconsMaterial.connection_ethernet;
            case ConnectionTransport.Wifi:      return IconsMaterial.connection_wifi;
            
            default:                            return "";
        } // switch
    }
}