<button mat-icon-button aria-label="Active Devices"
    routerLink="/devices/online"

    [disabled]="num <= 0"
    [matTooltip]="tooltip"
>
    <!-- (click)="onClick($event)" -->

    <mat-icon [matBadge]="num" matBadgePosition="above before" matBadgeSize="medium" [matBadgeColor]="colour" [matBadgeHidden]="num <= 0">
        {{ refresh ? iconRefresh : iconDevices }}
    </mat-icon>
    <span class="cdk-visually-hidden">{{ num }}</span>
    <!-- <mat-icon [matBadge]="num" matBadgePosition="above after" matBadgeSize="medium" [matBadgeColor]="colour" [matBadgeHidden]="num <= 0">{{ refresh ? '⟳' : 'devices' }}</mat-icon> -->
</button>