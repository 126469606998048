<div class="mat-button">
    <button  mat-stroked-button
        [ngClass]="connected ? 'wifi-connected' : (version ? 'wifi-' + version.standard : '')"
        matTooltip="{{ (connected ? 'Connected - ' : '') + (version ? version.displayVersion + ' (' + version.standard + ')' : 'Wifi unknown') }}"
        matTooltipClass="tooltip-multiline"
        #tt="matTooltip"
        (click)="tt.show()">

        <mat-icon class="icon-1-5x" aria-label="Wifi AP">{{ icon }}</mat-icon>

    </button>
</div>