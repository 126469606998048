'use strict'


const _types = {
    online:    'online',
    offline:   'offline',

    completed: 'completed',
    paused:    'paused',
    started:   'started',
    stopped:   'stopped',

    unknown:   'unknown'
}; // _types


export class ElementStatus
{
    static Online    = new ElementStatus(_types.online);
    static Offline   = new ElementStatus(_types.offline);

    static Completed = new ElementStatus(_types.completed);
    static Paused    = new ElementStatus(_types.paused);
    static Started   = new ElementStatus(_types.started);
    static Stopped   = new ElementStatus(_types.stopped);

    static Unknown   = new ElementStatus(_types.unknown);

    // Deprecated
    // static online  = new ElementStatus(_types.online);
    // static offline = new ElementStatus(_types.offline);


    constructor(d)
    {
        this._type = d;
    }


    // Factory
    static get(d)
    {
        switch (d) {
            case _types.online:     return ElementStatus.Online;
            case _types.offline:    return ElementStatus.Offline;

            case _types.completed:  return ElementStatus.Completed;
            case _types.paused:     return ElementStatus.Paused;
            case _types.started:    return ElementStatus.Started;
            case _types.Stopped:    return ElementStatus.stopped;

            case _types.unknown:
            default:
                return ElementStatus.Unknown;
        } // switch
    }


    static get types()
    {
        return _types;
    }


    get status()
    {
        return this._type;
    }


    // Override
    toString()
    {
        return "ElementStatus: " + this.status;
    }
}