import { CommonModule }         from '@angular/common';
import {
    Component,
    Input,
}                               from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';
// import {
//     WifiCell,
//     WifiVersion
// }                               from '@ObjElements/wifi-cells/';

import {
    WifiCell,
    WifiVersion
}               from '@Common/Elements/WifiCell/';

import { ButtonBaseComponent }  from './button-base.component';


@Component({
    selector:    'qp-wificell-version-icon',
    templateUrl: 'wificell-version-icon.component.html',
    styleUrls:   [
        'wificell-version-icon.component.css'
    ],
    imports: [
        CommonModule,
        MaterialCustomModule
    ]
})
export class WifiCellVersionIconComponent extends ButtonBaseComponent
{
    private static readonly _iconUnknown:   string = IconsMaterial.wifi_version_unknown;
    private static readonly _iconConnected: string = IconsMaterial.wifi_connected;

    private static readonly _icons: Record<string, string> = {
        [WifiVersion.Ac.standard]:      IconsMaterial.wifi_version_5,
        [WifiVersion.Ad.standard]:      IconsMaterial.wifi_version_gig,
        [WifiVersion.Ax.standard]:      IconsMaterial.wifi_version_6,
        [WifiVersion.Be.standard]:      IconsMaterial.wifi_version_7,
        [WifiVersion.Legacy.standard]:  IconsMaterial.wifi_version_legacy,
        [WifiVersion.N.standard]:       IconsMaterial.wifi_version_4,
        [WifiVersion.Unknown.standard]: WifiCellVersionIconComponent._iconUnknown
    }; // _icons

    private _icon:                  string | undefined;
    private _version:               WifiVersion           ;// = WifiVersion.WifiUnknown;


    @Input()
    public cell: WifiCell;


    //
    // Getters and setters
    //
    public get connected(): boolean
    {
        return ((this.cell instanceof WifiCell) && this.cell.connected);
    }

    
    @Input()
    public get icon(): string | undefined
    {
        return (this._icon)
            ? this._icon
            : WifiCellVersionIconComponent.getIcon((this.cell instanceof WifiCell) ? this.cell : this.version);
    }

    public set icon(d)
    {
        this._icon = d;
    }


    @Input()
    public get version(): WifiVersion | undefined
    {
        return (this._version)
            ? this._version
            : ((this.cell instanceof WifiCell)
                ? this.cell.version
                : this._version
            );
    }

    public set version(d: WifiVersion | undefined)
    {
        // console.error(d)
        this._version = (d instanceof WifiVersion) ? d : WifiVersion.Unknown;
    }


    //
    // Private methodss
    //
    private static getIcon(d: WifiVersion | WifiCell | undefined): string
    {
        if (d instanceof WifiCell && d.connected) return WifiCellVersionIconComponent._iconConnected;

        const t: WifiVersion | string | undefined = (d instanceof WifiCell) ? d.version : d;
        const i: string | undefined = (t instanceof WifiVersion) ? WifiCellVersionIconComponent._icons[t.standard] : undefined;

        return (i) ? i : WifiCellVersionIconComponent._iconUnknown;
    }
}