import { CommonModule }       from '@angular/common';

import {
    Component,
    EventEmitter,
    Input,
    Output
}                             from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                             from '@angular/router';

import { BaseComponent }      from '@Base/';
import { DateTimePipe }       from '@GuiElements/datetime.pipe'
import { TableDataComponent } from '@GuiElements/table-data.component';

import { Element }            from './element.class';
import { ElementCommon }      from '@Common/Elements/Element.class';//'./';


@Component({
    selector:    'qp-element-info',
    templateUrl: 'element-info.component.html',
    styleUrls:   [
        'element-info.component.css'
    ],
    imports:     [
        TableDataComponent,

        DateTimePipe,

        CommonModule
    ]
    // standalone: false
})
export class ElementInfoComponent extends BaseComponent
{
    public _d:    any;
    public _elem: Element | undefined = undefined;

    constructor(protected readonly ActRoute: ActivatedRoute,
                protected readonly Router:   Router)
    {
        super();
    }


    @Input()
    // public data:        any = undefined; 
    public get data(): any
    {
        return this._d
    }

    public set data(v: any)
    {
        this._d = v;
    }
    // public data:  Element = undefined;


    @Input()
    public light:           boolean = false;

    @Input()
    public matCard:         boolean = false;

    @Input()
    public showFull:        boolean = true;

    @Input()
    public showDescription: boolean = true;

    @Input()
    public showLocation:    boolean = true;

    @Input()
    public showId:          boolean = false;

    @Input()
    public showType:        boolean = false;

    @Input()
    public showUpdated:     boolean = false;
    

    @Output()
    public selected:        EventEmitter<any> = new EventEmitter(); // used to inform parents if 'selected'


    //
    // Public methods
    //
    public listObj(d: ElementCommon): any[]
    {
        // Child obejcts can override

        return [];
    }


    public select(d: Element): void
    {
        this.selected.emit(d);
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();
    }
}