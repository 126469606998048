<div class="div" *ngIf="d">
    <mat-card *ngIf="matCard">

        <mat-card-subtitle *ngIf="matCard">Battery</mat-card-subtitle>
        <h4 *ngIf="! matCard" class="mat-subheading-1">Battery</h4>

        <mat-card-content *ngIf="matCard">
            <qp-table-data
                [data]="
                    [
                        { k: 'Level',         v: d.batteryLevelStr ? d.batteryLevelStr + '%' : 'unknown'                           },
                        { k: 'Charging',      v: d.batteryCharging ? (d.batteryConnected ? '' + d.batteryConnected : 'yes') : 'no' },
                        { k: 'Health',        v: d.batteryHealth                                                                   },

                        ! d.batteryCharging
                            ? { k: 'Current', v: d.batteryCurrentNowStr ? d.batteryCurrentNowStr + ' mA' : ''                      }
                            : undefined
                    ]">
            </qp-table-data>

        </mat-card-content>
    </mat-card>
</div>