@if (d && d.type) {
    <div class="div">
        @if (matCard) {
            <mat-card>
                @if (matCard) {
                    <mat-card-subtitle>Connection</mat-card-subtitle>
                }
                @else {
                    <h4 class="mat-subheading-1">Connection</h4>
                }

                <mat-card-content>
                    <qp-table-data
                        [nullValues]=false [data]="
                            [
                                { k: 'Type', v: d.type?.type },
                            ]">
                    </qp-table-data>

                </mat-card-content>
            </mat-card>
        }
    </div>
}