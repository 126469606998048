import {
    Component,
    EventEmitter,
    Input,
    Output
}                               from '@angular/core';

import { BaseComponent }        from '@Base/';
import {
    TableDataComponent,
    TableDataValue
}                               from '@GuiElements/table-data.component';
import { MaterialCustomModule } from '@Material/';


@Component({
    selector:    'qp-deviceid',
    templateUrl: 'deviceid.component.html',
    styleUrls:   ['deviceid.component.css'],
    imports:     [
        TableDataComponent,
        
        MaterialCustomModule
    ]
})
export class DeviceIdComponent extends BaseComponent
{
    @Input()
    public link:    boolean                                = false;


    @Input()
    public text:    string                                 = "";


    @Output()
    public readonly selected: EventEmitter<TableDataValue> = new EventEmitter();


    //
    // Public methods
    //
    public select(d: TableDataValue)
    {
        this.selected.emit(d);
    }
}