import { CommonModule }              from '@angular/common';
import {
    Component,
    Input
}                                    from '@angular/core';
import {
    DomSanitizer,
    SafeResourceUrl
}                                    from '@angular/platform-browser'
import { Router }                    from '@angular/router';
import { Observable }                from 'rxjs';

import { BaseComponent }             from '@Base/';
import { HeaderService }             from '@Header/header.service';

import { SecureAuthenticationGuard } from './secure-authentication-guard.service';
import { AuthenticationService }     from '../authentication.service';
import { 
    User,
    UserService
}                                    from '../user.service';


@Component({
    selector:    'qp-securelogin',
    templateUrl: 'securelogin.component.html',
    styleUrls:   [
        'securelogin.component.css'
    ],
    imports:     [
        CommonModule
    ]
})
export class SecureLoginComponent extends BaseComponent
{
    private _url:     string;
    private _urlSafe: SafeResourceUrl;


    constructor(private readonly Router:                    Router,

                private readonly DomSanitizer:              DomSanitizer,
                private readonly HeaderService:             HeaderService,
                private readonly SecureAuthenticationGuard: SecureAuthenticationGuard,
                private readonly UserService:               UserService)
    {
        super();

        this.HeaderService.user = true; // just enable user button header bar

        // const nav: Navigation = this.Router.getCurrentNavigation();
        // const navExtras: NavigationExtras = nav ? nav.extras : undefined;
        // if (Object(navExtras) && 'state' in Object(navExtras)) {
        //     const state = Object(navExtras).state as {
        //         url: string
        //     };
        //     if (Object(state) && 'url' in Object(state)) this.url = Object(state).url;
        // }

        if (this.UserService) {
            const obs: Observable<User | undefined> | undefined = this.UserService.user$;
            if (obs instanceof Observable) this.sub = obs
                .subscribe((d: User | undefined): void => {
                    // Only do if URL changed - will trigger reload of secure frame
                    if (d instanceof User && d.isValid && d.extraUrl && this.urlI != d.extraUrl) this.url = d.extraUrl;
                }); // subscribe
        }
    }


    //
    // Getters and setters
    //
    private get urlI(): string
    {
        return this._url;
    }

    @Input()
    public set url(d: string)
    {
        if (this._url = d) this._urlSafe = this.DomSanitizer.bypassSecurityTrustResourceUrl(this.urlI);
    }


    public get urlSafe(): SafeResourceUrl | undefined
    {
        // Needed for cross-site loading
        return this._urlSafe;
    }
}