import { CommonModule }          from '@angular/common';
import {
    Component,
    Input
}                                from '@angular/core';

import { ElementInfoComponent }  from '../../element/element/element-info.component';
import { DateTimePipe }          from '@GuiElements/datetime.pipe';
import { TableDataComponent }    from '@GuiElements/table-data.component';
import { MaterialCustomModule }  from '@Material/';

import { TestFtpInfoComponent }  from '../test-ftp/';
import { TestIcmpInfoComponent } from '../test-icmp/';

import { TestIconComponent }     from './test-icon.component';

import {
    Test,
    // TestTypes
}                                from '../';


@Component({
    selector:    'qp-test-info',
    templateUrl: 'test-info.component.html',
    styleUrls:   [
        'test-info.component.css'
    ],
    imports:     [
        CommonModule,

        DateTimePipe,
        TableDataComponent,
        MaterialCustomModule,

        TestFtpInfoComponent,
        TestIcmpInfoComponent,

        TestIconComponent
    ]
})
export class TestInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:                     any;


    @Input()
    public nullValues:               boolean = true;
    

    @Input()
    public showMobileCellTechnology: boolean = true;


    @Input()
    public showMobileCellLevel:      boolean = true;


    @Input()
    public useExpansion:             boolean = false;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }

    get TestTypes(): typeof Test.Types
    {
        return Test.Types;
    }

    
    get type(): string// MobileCellType//MobileCell.Technology
    {
        return this.d?.type;
    }


    // get technology(): string
    // {
    //     return (this.d instanceof MobileCell && this.d.technology instanceof MobileCellType)
    //         ? this.d.technology.type
    //         : "";
    // }


    // get technologyDetail(): string
    // {
    //     return (this.d instanceof MobileCell)
    //         ? this.d.technologydetail
    //         : "";
    // }
}