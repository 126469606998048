import { Injectable }                from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscriber,

    delay,
    of,
    tap,
    timer
}                                    from 'rxjs';

// import * as io                       from 'socket.io-client';
import {
    connect as ioConnect,
    Manager as ioManager,
    Socket  as ioSocket
}                                    from 'socket.io-client';
// import { Packet as ioPacket }        from 'socket.io-parser';

import { BaseService }               from '@Base/';
import { 
    User,
    UserService
}                                    from '@Login/';
import { 
    SocketIo,
    SocketIoEvent
}                                    from '@Utils/';

import { WebMsgsModule as Messages } from '@Common/';
export { WebMsgsModule as Messages } from '@Common/';


const _wa  = Messages.attributes;
const _we  = Messages.events;
const _wta = Messages.msgTypesAttributes;
const _wt  = Messages.msgTypesInfo;


interface MsgSub {
    timer: any
    sub:   Subject<any>
} // MsgSub


export interface MessageInterface {
    // [TBD]
    // [_wta.alarm as string]: any,

    'alarm':   any,
    'data':    Object,
    'message': string,

    // [TBD]
    testResultType: string
}


export interface SocketEvent {
    d:    any
    val1: any,
    val2: any
} // SocketEvent


@Injectable({
    providedIn: 'root'
})
export class MessageService extends BaseService
{
    public static readonly events = {
        ...SocketIoEvent.events,
        // connect:          'connect',
        // // connectTimeout:   'connect_timeout',
        // // connectError:     'connect_error',
        // reconnect:        'reconnect',
        // reconnectAttempt: 'reconnect_attempt',
        // reconnectError:   'reconnect_error',
        // reconnecting:     'reconnecting',
        // reconnectFailed:  'reconnect_failed',

        // open:             'open',
        // close:            'close',
        // data:             'data',
        // error:            'error',
        // ping:             'ping',
        // packet:           'packet',

        // disconnect:       _wt.disconnect,    //'disconnect',

        heartbeat:        _wt.heartbeat,     //'heartbeat',
        heartbeatResp:    _wt.heartbeatresp, //'heartbeatresp',
        login:            _wt.login,         //'login',
        logout:           _wt.logout,        //'logout',
        message:          _wta.message,      //'message',
        reqid:            _wta.reqid         //'reqid'
    };

    private static readonly hbTimer:                    number  = 60;      // secs
    private static readonly maxHb:                      number  = 5;
    private static readonly msgProgressDelay:           number  = 1;       // secs
    private static readonly msgTimeout:                 number  = 30;      // secs
    private static readonly socketReconnection:         boolean = true;
    private static readonly socketReconnectionDelay:    number  = 5;       // secs
    private static readonly socketReconnectionDelayMax: number  = 10 * 60; // secs, 10 mins

    public  messages:                   typeof Messages;

    private _connected$:                BehaviorSubject<boolean>;
    private _messageEvent$:             Subject<MessageInterface>;
    private _socketEvent$:              Subject<SocketEvent>;

    private hbId:                       number  = 0;  // heartbeat ID
    private id:                         number  = 0;  // used as a msg ID
    private mTimeout:                   any     = {};
    private readonly msgsOutstanding            = new Map();
    private _socket:                    SocketIo;
    private _user:                      User;


    constructor(private readonly UserService: UserService)
    { 
        super();

        this.messages       = Messages; // can get message types by those injecting this service

        this._connected$    = new BehaviorSubject<boolean>(false);
        this._messageEvent$ = new Subject<MessageInterface>();
        this._socketEvent$  = new Subject<SocketEvent>();

        // Update from common
        // MessageService.events.disconnect    = _wt.disconnect;
        // MessageService.events.heartbeat     = _wt.heartbeat;
        // MessageService.events.heartbeatResp = _wt.heartbeatresp;
        // MessageService.events.login         = _wt.login;
        // MessageService.events.logout        = _wt.logout;
        // MessageService.events.message       = _wta.message;
        // MessageService.events.reqid         = _wta.reqid; // [TBD]
    }


    // Getters
    public get connected$(): Observable<boolean> | undefined
    {
        return (this._connected$ instanceof Subject) ? this._connected$.asObservable() : undefined;
    }


    public get messageEvent$(): Observable<MessageInterface> | undefined
    {
        return (this._messageEvent$ instanceof Subject) ? this._messageEvent$.asObservable() : undefined;
    }


    private get socket(): SocketIo
    {
        return this._socket;
    }


    public get socketEvent$(): Observable<SocketEvent> | undefined
    {
        return (this._socketEvent$ instanceof Subject) ? this._socketEvent$.asObservable() : undefined;
    }


    //
    // Public methods
    //

    // Called by (overarching) AppComponent to trigger call to UserService;
    // can't do in initialise() as service can't access another service
    // during initialise(), which here is called by constructor
    public configure(): void
    {
        this._socket = this.createSocket(true);

        if (this.UserService && this.UserService.user$ instanceof Observable)
            this.UserService.user$.subscribe((d: User | undefined): void => {
                if (d instanceof User) this._user = d;
            }); // subscribe

        console.debug("Configured MessageService");
    }


    public sendMsgGet(arg: object | string, d?: { [key: string]: any }, enableProgress?: boolean): Observable<any> | undefined
    {
        if (typeof arg === "string") (d = d ? d : {})[_wta.info] = arg;
        return this.sendMsg(Messages.msgTypes.get, Object(d));
    }


    public sendMsgSet(arg: object | string, info: string, d?: { [key: string]: any }, enableProgress?: boolean): Observable<any> | undefined
    {
        if (typeof arg === "string") {
            (d = d ? d : {})[_wta.action] = arg;
            d[_wta.info] = info;
        }
        return this.sendMsg(Messages.msgTypes.set, Object(d));
    }


    public sendMsg(msg: string, d: object, enableProgress?: boolean): Observable<any> | undefined
    {
        return this.sendMsgCommon(undefined, msg, d, enableProgress);
    }


    public sendMsgCommon(raw: any, msg: string, d: any, enableProgress?: boolean): Observable<any> | undefined
    {
        if (msg) {
            if (this.socket instanceof SocketIo) {
                d = d ? d : {};

                // Add user token (except in login case)
                if (this._user) d.token = this._user.token;

                // [TBD] If no token; don't send msg and go to login screen?  Or let server deal with it?

                // Add msg ID to msg and create Subject to inform caller of response
                this.msgsOutstanding.set(
                    d[_wta.reqid] = ++this.id,
                    {
                        sub:   new Subject<any>(),
                        timer: of(true)
                            .pipe(
                                delay(MessageService.msgTimeout * 1000)
                            )
                            .pipe(
                                tap((): void => {
                                    console.warn("Message response timed out: id = " + d[_wta.reqid] + ", timeout = " + MessageService.msgTimeout + " secs,  message = " + msg);
                                    console.debug(d);
                                    if (raw) console.warn(raw);
                                    this.msgsOutstanding.get(d[_wta.reqid]).sub.next(undefined);
                                    this.msgsOutstanding.get(d[_wta.reqid]).sub.error();
                                })
                            ).subscribe()
                    } as MsgSub
                ); // set

// [TBD] guard timer

                this.socket.emit(msg, d, raw);
//                 const s: ioSocket | undefined = this.socket.socket;
//                 try {
//                     if (s instanceof ioSocket) {
// // console.debug(msg);
// // console.debug(d);
// if (raw) console.debug(raw);
//                         if (raw) s.emit(raw, msg, d);
//                         else     s.emit(msg, d);
//                         // s.emit((raw ? raw : _wta.message), msg, d);
//                     }
//                     else {
//                         console.warn("Unable to send msg");
//                         console.debug(JSON.stringify(s));
//                     }
//                 }
//                 catch (e) {
//                     console.error("Failed to send msg");
//                     console.debug(JSON.stringify(s));
//                     console.debug(JSON.stringify(e));
//                 }

                if (enableProgress) {
                    // Wait for 1 sec
                    if (this.mTimeout && this.mTimeout.timer) {
                        clearTimeout(this.mTimeout.timer);
                        this.mTimeout = {};
                    }
                }
                return this.msgsOutstanding.get(this.id).sub.asObservable();
            }
            else {
                console.error("Unable to send msg; invalid socket");
                console.debug(this.socket);
            }
        }

        return undefined;
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        //let server = "0.0.0.0";
        //let port   = 3000;
        // defaults to domain and port from which the original page was served
        //('http://' + server + ':' + port, {
        //                 reconnect: true
        //             });

        // socket.io uses exponential backoff on reconnections, possibly also with a randomisation factor


        console.debug("Initialised Message service");
    }


    //
    // Private methods
    //
    private createSocket(forceNew: boolean = true): SocketIo
    {
        // const socket: ioSocket = ioConnect instanceof Function ? ioConnect() : undefined;
        // const socket: ioSocket = ioConnect instanceof Function ? ioConnect() : undefined;//io.connect();

        const socket: SocketIo = SocketIo.get(
            (ioConnect instanceof Function)
                ? ioConnect({
                    'forceNew': forceNew
                })
                : undefined
        ); // get()
        if (socket instanceof SocketIo) {
            socket.reconnection         = true;
            socket.reconnectionDelay    = MessageService.socketReconnectionDelay    * 1000;
            socket.reconnectionDelayMax = MessageService.socketReconnectionDelayMax * 1000;

            // Application messages
            let obs = socket.socketMsg$;
            if (obs instanceof Observable)
                this.sub = obs
                    .subscribe((d: SocketIoEvent): void => this.processSocketMsg(socket, d));

            // Socket events
            obs = socket.socketEvent$;
            if (obs instanceof Observable)
                this.sub = obs
                    .subscribe((d: SocketIoEvent): void => this.processSocketEvent(socket, d));
        }
        else {
            console.error("Unable to create IO socket");
            console.debug(socket);
        }

        return socket;
    }


    private messageEvent(d: MessageInterface): void
    {
        if (this._messageEvent$ instanceof Subject && d) this._messageEvent$.next(d);
    }


    private processMessage(d: any, check: boolean = true): boolean
    {
        if (d) {
            // [TBD] Check reflected reqId?
            if (d[_wta.reqid] === this.mTimeout.id) {
                if (this.mTimeout.timer) clearTimeout(this.mTimeout.timer);
                this.mTimeout = {};
            }

            if (d[_wta.message]) {
                const msgId: number  = d[_wta.reqid];
                const msgSub: MsgSub = msgId ? this.msgsOutstanding.get(msgId) : undefined;
                if (msgSub) {
                    console.debug("Calling observable for msg: " + d[_wta.message] + ", " + msgId);

                    if (msgSub.timer instanceof Subscriber) {
                        console.debug("Removing guard timer: " + msgId);
                        console.debug(d)
                        msgSub.timer.unsubscribe();
                    }
                    if (msgSub.sub instanceof Subject) {
                        msgSub.sub.next(d);
                        msgSub.sub.complete();
                    }
                    this.msgsOutstanding.delete(msgId);
                    return true;
                }
                else {
                    this.messageEvent(d as MessageInterface);
                }
            }
            else if (check) {
                console.warn("Received invalid '" + _wta.message + "'");
                console.debug(d);
            }
        }

        return false;
    }


    private processSocketEvent(s: SocketIo, d: SocketIoEvent): void
    {
        if (d instanceof SocketIoEvent && d.event) {
            switch (d.event) {
                // IO Socket events
                case SocketIoEvent.events.connect:
                case SocketIoEvent.events.reconnect:
                    console.info("Connected to server: " + s.remoteAddress);
                    if (this._connected$ instanceof Subject) this._connected$.next(true); // inform interested parties about a (re)connection

                    // Start heartbeats
                    // if (s instanceof SocketIo) {
                    //     console.debug("Starting HTTP heartbeats: " + MessageService.hbTimer);
                    //     this.startHeartbeatTimer(s.socket, MessageService.hbTimer);
                    // }
                break;

                case SocketIoEvent.events.connect_error:
                    if (s instanceof SocketIo) {
                        console.info("Connection to server (HTTP) error: " + s.remoteAddress + ", " + JSON.stringify(d.data));
                    }
                break;

                case SocketIoEvent.events.connect_timeout:
                    if (s instanceof SocketIo) {
                //     console.info("Connection to server (HTTP) timeout: " + s.remoteAddress)
                    }
                break;

                case SocketIoEvent.events.close: // Don't always get disconnect
                case SocketIoEvent.events.disconnect:
                    if (s instanceof SocketIo) console.warn("Disconnected from server: " + s.remoteAddress + ", " + d.data + ": " + d.event);
                    if (this._connected$ instanceof Subject) this._connected$.next(false);
                break;

                // IO Manager events
                case SocketIoEvent.events.error:
                    if (s instanceof SocketIo) {
                        console.warn("Connection error for server: " +  s.remoteAddress + JSON.stringify(d.data));
                        // this._socket = this.createSocket(true);
                    }
                break;

                // No default
            } // switch

            this.socketEvent(d.event, d.data);
        }
        // else {
        //     console.warn("Empty SocketIO event arg");
        //     console.debug(d);
        // }
    }


    private processSocketMsg(s: SocketIo, d: SocketIoEvent): void
    {
        if (d instanceof SocketIoEvent && d.event) {
            switch (d.event) {
                case MessageService.events.heartbeat:
                    // console.info("Heartbeat received from server, responding: " + s.remoteAddress);
                    if (s instanceof SocketIo) {
                        MessageService.sendHeartbeat(s.socket as any, true, d.data ? d.data[d.event] : undefined);
                    }
                break;

                case MessageService.events.heartbeatResp:
                    //  console.info("Heartbeat response received from server: " + s.remoteAddress);

                    // [BD] Check response Id?   
                break;

                case MessageService.events.logout:
                    if (! this.processMessage({[_wta.message]: d.data ? {...d.event, ...d.data} : d.event}, true)) { // Needed for processMessage
                        // No or invalid token previously sent; force back to login screen
                        console.info("Asynchronous logout received: " + s.remoteAddress);
                        if (d.data) {
                            this.socketEvent(d.event, d.data[_wta.info], d.data[_wta.timer]);
                            if (this.UserService && this.UserService.logout instanceof Function) {
                                this.sub = timer((d.data[_wta.timer] ? d.data[_wta.timer] : 0) * 1000).subscribe((d: number): void => {
                                    this.UserService.logout();
                                }); // timeout
                            }
                        }
                    } 
                break;                            

                case MessageService.events.login:
                    console.debug("Login data received: " + s.remoteAddress);
                    (d.data = d.data ? d.data : {})[_wta.message] = d.event; // Needed for processMessage
                    
                // Allow fall-through

                case MessageService.events.message:
                    this.processMessage(d.data);
                break;
            } // switch
        }
        // else {
        //     console.debug("Unknown message received");
        //     console.debug(d);
        // }
    }
    
    
    private static sendHeartbeat(s: ioSocket, resp: boolean = false, id? : number): void
    {
        if (Object(s) && s.emit instanceof Function) s.emit(
            resp ? _wt.heartbeatresp : _wt.heartbeat,
            { [_wt.heartbeat]: id }
        ); // emit
    }


    private socketEvent(d: any, val1?: any, val2?: any): void
    {
        if (this._socketEvent$ instanceof Subject)
            this._socketEvent$.next(
                {
                    d:    d,
                    val1: val1,
                    val2: val2
                } as SocketEvent
            ); // next
    }


    private startHeartbeatTimer(s: ioSocket | undefined, timer: number): void
    {
        if (s instanceof ioSocket) {
            const hbTimer: number = (s as any).hbTimer;
            if (hbTimer) clearTimeout(hbTimer);

            (s as any).hbTimer = setTimeout((): any => {
                MessageService.sendHeartbeat(s, false, this.hbId++);
                this.startHeartbeatTimer(s, timer);
            }, timer * 1000); // setTimeout
        }
    }
}