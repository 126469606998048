'use strict'


const _types = {
    bluetooth: 'bluetooth',
    cellular:  'cellular',
    ethernet:  'ethernet',
    wifi:      'wifi',
    unknown:   'unknown'
}; // _types


export class ConnectionTransport
{
    static Bluetooth = new ConnectionTransport(_types.bluetooth);
    static Cellular  = new ConnectionTransport(_types.cellular);
    static Ethernet  = new ConnectionTransport(_types.ethernet);
    static Wifi      = new ConnectionTransport(_types.wifi);
    static Unknown   = new ConnectionTransport(_types.unknown);
  

    constructor(d)
    {
        this._type = d;
    }


    // Factory
    static get(d)
    {
        if (d instanceof ConnectionTransport) return d;
        else switch(d) {
            case _types.bluetooth: return ConnectionTransport.Bluetooth;
            case _types.cellular:  return ConnectionTransport.Cellular;
            case _types.ethernet:  return ConnectionTransport.Ethernet;
            case _types.wifi:      return ConnectionTransport.Wifi;

            case _types.unknown:
            default:
                return ConnectionTransport.Unknown;
        } // switch
    }


    get type()
    {
        return this._type;
    }


    // Override
    toString()
    {
        return "ConnectionTransport: " + this.state;
    }
}