<!--<div *ngIf="data && data.length > 0">-->
<div class="fill" flex layout-fill [hidden]="! data || data.length <= 0">
    <div class="header" fxLayout="row">
        @if (searchEnabled) {
            <div class="dense-4">
                <mat-form-field>
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput type="search" placeholder="Search" class="search-input"
                                    (keyup)="filterChange($event)">
                                    <!-- (keyup)="applyFilter($event.target.value)"> -->
                </mat-form-field>
            </div>
        }

        @if (paginatorEnabled && dataQuantity > pageSizeDefault) {
            <div class="dense-4">
                <mat-paginator
                    [length]="dataQuantity"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageSize]="pageSizeDefault"
                    showFirstLastButtons=true

                    (page)="pageChange($event)">
                </mat-paginator>
            </div>
        }
    </div>


    <!-- <div [hidden]="dataSrc.filteredData.length <= 0"> -->
    <div class="content">
        <table mat-table [dataSource]="dataSrc" [trackBy]="trackBy" matSort (matSortChange)="sortChange($event)" class="mat-elevation-z8">
            <!-- Columns definition -->
            @if (selectEnabled) {
                <ng-container matColumnDef="{{ selectAttr }}">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="onSelectionRowChange(row)"
                            [checked]="selectionModel.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
            }

            @for (key of attrsSrc; track key) {
                <!-- <ng-container *ngFor="let key of attrsSrc" matColumnDef="{{ key.alias }}"> -->
                <ng-container matColumnDef="{{ key.alias }}">
                    <!-- can't use ngTemplateOutlet and *ngIf here as can't link to templates within table definition - context not valid -->
                    @if (key.name != stateAttrG && key.name != statusAttrG) {
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ key.alias | titlecase }}</th>
                        <td mat-cell *matCellDef="let element" [ngClass]="element?.class">{{ element[key.name] }}</td>
                        <!-- <td mat-cell *matCellDef="let element" [ngClass]="element?.class">{{ element[key.name] }}</td> -->
                    }

                    @if (key.name == stateAttrG && stateEnabled) {
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th> <!-- no title for state column -->
                        <td mat-cell *matCellDef="let element" [ngClass]="element?.class">
                        <!-- <td mat-cell *matCellDef="let element" [ngClass]="element?.class"></td> -->
                            <qp-status-indicator [state]="element[key.name]"></qp-status-indicator>
                        </td>
                    }

                    @if (key.name == statusAttrG && stateEnabled) {
                        <th mat-header-cell *matHeaderCellDef mat-sort-header></th> <!-- no title for state column -->
                        <td mat-cell *matCellDef="let element" [ngClass]="element?.class">
                        <!-- <td mat-cell *matCellDef="let element" [ngClass]="element?.class"></td> -->
                            <qp-status-icon-survey [state]="element[key.name]"></qp-status-icon-survey>
                        </td>
                    }
                </ng-container>
            }

            <!-- Rows definition - which order the columns defined above should be in -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr #tableRow      *matRowDef="let row; columns: displayedColumns;" class="mat-row element-row"
                (click)="!row?.disabled && select(row)"
            >
                <!-- [class.disabled]="row?.disabled" -->

                <ng-container cdkCellOutlet></ng-container>
                <div matRipple [matRippleTrigger]="tableRow" class="ripple-overlay"></div>
            </tr>
        </table>
    </div>
<!--        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 250]" [pageSize]="pageSizeDefault" *ngIf="paginatorEnabled && dataSrc.filteredData.length > pageSizeDefault" showFirstLastButtons></mat-paginator> -->
    <!-- </div> -->
</div>