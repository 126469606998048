import { CommonModule }         from '@angular/common';

import {
    Component,
    Input
}                               from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../../element/element/element-info.component';
import { DataElementComponent } from '@GuiElements/data-element.component';
import { IconsService }         from '@Icons/';
import { MaterialCustomModule } from '@Material/';

import { SimCommon }            from './sim.class';


@Component({
    selector:    'qp-sim-info',
    templateUrl: 'sim-info.component.html',
    styleUrls:   [
        'sim-info.component.css'

    ],
    imports:     [
        DataElementComponent,

        CommonModule,
        MaterialCustomModule
    ]
})
export class SimInfoComponent extends ElementInfoComponent
{
    public icon: any = undefined;


    @Input()
    public simNum: number = 0;


    constructor(                 ActRoute: ActivatedRoute,
                                 Router:   Router,
                                 
                private readonly IconsService: IconsService)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): SimCommon
    {
        return this.data as SimCommon;
    }


    //
    // Protected methods
    //

    // Override
    protected override initialise(): void
    {
        // Don't call super()

        this.timer = window.setTimeout(() => {
            //this.d = this.marker ? this.marker.element as Sim : this.sim;

            if (this.d && this.IconsService) {
                this.IconsService.getIconMobileNetwork(
                    this.d.plmn ? this.d.plmn.toLocaleString() : "",
                    this.d.operator,

                    // Callback
                    ((i: any): void => {
                        console.log(i);
                        if (i) this.icon = i;
                    })
                ); // getIconMobileNetwork()
            }

        }); // setTimeout
    }
}