'use strict'

import { BehaviorSubject }   from 'rxjs';

// import { DeviceMsgsModule }  from '../messages/index.mjs';
import { ElementCommon }     from './Element.class.mjs';
import { ElementState }      from './ElementState.class.mjs';
import { ElementType }       from './ElementType.class.mjs';
import { GeolocationCommon } from './Devices/Attributes/Geolocation.class.mjs';
// import { NotificationCommon }       from './Notification/Notification.class.mjs';
// import { NotificationHelperCommon } from './Notification/Notification-helper.class.mjs';


const _attrs = {
    _geolocation:   '_geolocation',
    _notifications: '_notifications',
    _state:         '_state'
}; // _attrs


const _attrsIgnoreValid = [
    _attrs._geolocation,
]; // _attrsIgnoreValid


// const _da    = DeviceMsgsModule.attributes;
// const _field = _da.elementmain;
const _field = ElementType.Elementmain;//_da.elementmain;


export class ElementMainCommon extends ElementCommon
{
    // Factory method
    static get(d, attrs)
    {
        return d
            ? (d instanceof ElementMainCommon
                ? d
                : new ElementMainCommon(d, attrs)
            )
            : undefined;
    }


    constructor(d, attrs)
    {
        super(d, attrs);

        this.marker   = undefined; // used by web client to store map marker
        this._notify$ = undefined; // used by web client; can't populate on server due to JSON.stringify() complainiung
    }


    static get attrs()
    {
        return _attrs;
    }


    static get field()
    {
        return _field;
    }


    //
    // Getters and setters
    //
    get geolocation()
    {
        return this[ElementMainCommon.attrs._geolocation];
    }

    get geolocationStr()
    {
        return (this.geolocation && this.geolocation.locationStr instanceof Function) ? this.geolocation.locationStr : "";
    }
    

    // [TBD] deprecated
    get notification()
    {
        return this.notify$;
    }

    get notify$() // Observable<ElementCommon>
    {
        if (! (this._notify$ instanceof BehaviorSubject)) this._notify$ = new BehaviorSubject(this);
        return (this._notify$ instanceof BehaviorSubject) ? this._notify$.asObservable() : undefined;
    }
        

    get notifications()
    {
        return undefined;//this[ElementMainCommon.attrs._notifications];
    }

    set notifications(d)
    {
        // return undefined;//(this[ElementMainCommon.attrs._notifications] = ElementMainCommon._processNotifications(d));
    }


    get state()
    {
        return this[ElementMainCommon.attrs._state];
    }

    get stateStr()
    {
        return this.state ? this.state.state : "";
    }

    set state(d)
    {
        const s =
            (d instanceof ElementState)
                ? d
                : ElementState.get(
                    (d instanceof Object)
                        ? Object.assign(new ElementState(), d).state
                        : d
                ); // get

        if (s instanceof ElementState && ElementState.Unknown !== s) (this[ElementMainCommon.attrs._state] = s);
                
        // return (this[ElementMainCommon.attrs._state] = 
        //     (d instanceof ElementState)
        //         ? d

        //         : ElementState.get(
        //             (d instanceof Object)
        //                 ? Object.assign(new ElementState(), d).state
        //                 : d
        //         ) // get
        // );
    }


    //
    // Public methods
    //
    processNotification(d)
    {
        // if (d = NotificationCommon.get(d)) {   // handles if d already NotificationCommon
        //     if (d.isSet) {
        //         if (! this[ElementMainCommon.attrs._notifications]) this[ElementMainCommon.attrs._notifications] = {};
        //         this[ElementMainCommon.attrs._notifications][d.id] = d; // will overwrite if ID is already present
        //     }
        //     else {
        //         delete this[ElementMainCommon.attrs._notifications][d.id]; // remove notification
        //     }
        // } // d

        return null;//d;

        // [TBD] if this happens, need to send this device to the clients - how to notify?
    }


    setState(d)
    {
        // Called externally to trigger state re-calc
        // Children can override if needed

        // if (! this.state instanceof ElementState) this.state = ElementState.Unknown;
    }


    //
    // Protected methods
    //

    // Used to convert to/from server/web client message

    // Override
    _merge(d, attrsE, attrsI)
    {
        super._merge(d, attrsE, {...ElementMainCommon.attrs, ...attrsI});

        if (d) {
            const e = GeolocationCommon.get(
                ! attrsE && d[ElementMainCommon.attrs._geolocation]
                    ? d[ElementMainCommon.attrs._geolocation]
                    : d,
                attrsE);

            if (! ElementCommon._isEmpty(e)) this[ElementMainCommon.attrs._geolocation] = e;
        }

        // Cause setter to create ElementState object
        if (this[ElementMainCommon.attrs._state]) {
            this.state = this[ElementMainCommon.attrs._state];
        }
        else {
            this.setState();
        }
                
        return this;
    }


    static _processNotification(d)
    {
        // return d ? NotificationHelperCommon.get(d) : undefined; // handles if d already NotificationCommon
    }


    static _processNotifications(d)
    {
        // Convert to objects
        // const o = {};
        // if (d && Array.isArray(d)) d.forEach((e) => {
        //     const m = ElementMainCommon._processNotification(e);
        //     if (m && m[NotificationCommon.attrs._id]) o[m[NotificationCommon.attrs._id]] = m;
        // }); // forEach

        // return o;
    }
}