<qp-button
    [icon]="Icons.device_alert"
    [name]="AppStrings.Device_Alert"
    [label]="AppStrings.Device_Alert"
    [colour]="''"
    [outline]="false"
    [disabled]="disabled"

    (clickL)="onClick($event)"
>
</qp-button>