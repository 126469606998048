import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';
import { DurationSecsPipe }     from '@GuiElements/duration.pipe';
import { TableDataComponent }   from '@GuiElements/table-data.component';

import {
    TestDataRatePipe,
    TestDataSizePipe,
    TestFtp
}                               from '../';


@Component({
    selector:    'qp-test-ftp-info',
    templateUrl: 'test-ftp-info.component.html',
    styleUrls:   [
        'test-ftp-info.component.css'
    ],
    imports:     [
        TableDataComponent,

        DurationSecsPipe,
        TestDataRatePipe,
        TestDataSizePipe
    ]
})
export class TestFtpInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:        any;

    @Input()
    public dataSource2: any;

    @Input()
    public nullValues:  boolean = true;

    
    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }
}