import { CommonModule }         from '@angular/common';
import { Component }            from '@angular/core';


import { IconsMaterial }        from '@Icons/';
import { MaterialCustomModule } from '@Material/';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
    selector:    'qp-sidebar-menu',
    templateUrl: 'sidebar-menu.component.html',
    styles:      [
        'sidebar-menu.component.css'
    ],
    imports:     [
        MaterialCustomModule
    ]
})
export class SideBarMenuComponent extends SideBarBaseComponent
{
    constructor()
    {
        super(IconsMaterial.menu);
    }
}