export const AppStrings = {
    Device:                 "Device",
    Device_Actions:         "Actions",
    Device_Alert:           "Alert device",
    Device_Config:          "Re-send configuration",
    Device_Disconnect:      "Disconnect",
    Device_LogEnable:       "Enable debug log",
    Device_LogDisable:      "Disable debug log",   

    Devices:                "Devices",
    Devices_Online:         "Devices Online",
    
    DeviceGroup:            "Device Group",
    DeviceGroup_Add:        "Add device group",
    DeviceGroup_Results:    "Results",
    DeviceGroup_RunTests:   "Run Tests",
    DeviceGroup_Start:      "Run",
    DeviceGroup_Stop:       "Stop",
    DeviceGroup_TestsStart: "Run tests",
    DeviceGroup_TestsStop:  "Stop tests",
    DeviceGroups:           "Device Groups",
    

    Image:                  "Image",
    Images:                 "Images",

    Live:                   "Live",

    Map:                    "Map",
    
    Survey:                 "Survey",
    Survey_GetReport:       "Download survey report",
    Survey_Report:          "Report",
    Surveys:                "Surveys",

    SurveyPoints:           "Points",
    SurveyPoint:            "Point",
    SurveySightings:        "Sightings",
    SurveySighting:         "Sighting",

    Test:                   "Test",
}