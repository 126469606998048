@if (d) {
    <div class="content">

        <ng-template [ngIf]="matCard" [ngIfThen]="useCard" [ngIfElse]="noCard"></ng-template>

        <ng-template #useCard>
            @if (useExpansion) {
                <mat-expansion-panel
                    [togglePosition]="'before'"
                >
                    <!-- <div class="content2"> -->
                    <mat-expansion-panel-header>
                        <ng-container *ngTemplateOutlet="Header"></ng-container>
                    </mat-expansion-panel-header>
    
                    <ng-template matExpansionPanelContent>
                        <ng-container *ngTemplateOutlet="Content"></ng-container>
                    </ng-template>
                    <!-- </div> -->
                </mat-expansion-panel>
            }
            @else {
                <mat-card>
                    <div class="content2">
                        <mat-card-subtitle>
                            <ng-container *ngTemplateOutlet="Header"></ng-container>
                        </mat-card-subtitle>

                        <mat-card-content>
                            <ng-container *ngTemplateOutlet="Content"></ng-container>
                        </mat-card-content>
                    </div>
                </mat-card>
            }
        </ng-template>


        <ng-template #noCard>
            <h4 class="mat-subheading-1">
                <ng-container *ngTemplateOutlet="Header"></ng-container>
            </h4>

            <ng-container *ngTemplateOutlet="Content"></ng-container>
        </ng-template>


        <ng-template #Header>
            <qp-test-icon
                [data]="d"
            >
            </qp-test-icon>            <!-- Mobile Cell -->
            <!-- <qp-mobilecell-technology-icon *ngIf="showMobileCellTechnology" class="icon" [technology]="technology" [technologydetail]="technologyDetail"></qp-mobilecell-technology-icon>
            <qp-mobilecell-level-icon      *ngIf="showMobileCellLevel"      class="icon" [mobilecell]="d">                                               </qp-mobilecell-level-icon> -->

            <!-- <qp-mobilecell-level-icon      *ngIf="showMobileCellLevel"      class="icon icon-1-5x" [mobilecell]="d">                                               </qp-mobilecell-level-icon> -->
        </ng-template>


        <!-- 'c' gives CSS classes for qp-table-data to apply using 'ngClass' -->
        <ng-template #Content>
            <qp-table-data
                [nullValues]="nullValues"
                [data]="
                    [
                        { k: 'Type', v: d.type  },
                        
                        d.deviceid
                            ? { k: 'Device Id', v: d.deviceid  }
                            : undefined,
                    ]"
            >
            </qp-table-data>
            <!-- { k: 'Technology',               v: d.technologyType + (d.technologydetail && d.technologyType !== d.technologydetail ? ' (' + d.technologydetail + ')' : ''), c: d.technologyType }, -->

            @switch (type) {
                @case (TestTypes.dns)     { <h4>Test DNS TBD</h4> }

                @case ([TestTypes.sftp, TestTypes.ftp].includes(type) && type) {
                    <qp-test-ftp-info [light]="light" [cell]="d" [nullValues]="nullValues"></qp-test-ftp-info>
                }
                @case (TestTypes.icmp)    { <qp-test-icmp-info [light]="light" [cell]="d" [nullValues]="nullValues"></qp-test-icmp-info> }

                @case (TestTypes.udp)     { <h4>Test UDP TBD</h4> }
                @case (TestTypes.voice)   { <h4>Test voice TBD</h4> }
                @case (TestTypes.web)     { <h4>Tets web TBD</h4> }
                @case (TestTypes.youtube) { <h4>Test Youtube TBD</h4> }

            } <!-- switch -->

            <qp-table-data
                [nullValues]="nullValues"
                [data]="
                    [
                        { k: 'Start', v: d.startI | datetime },
                        { k: 'End',   v: d.endI   | datetime }
                    ]"
            >
            </qp-table-data>
        <!-- { k: 'Technol -->
        </ng-template>
            
    </div> <!-- d.technology -->
}