import {
    Component,
    Input
}                        from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:    'qp-window-title',
   templateUrl: 'window-title.component.html',
   styleUrls:   ['window-title.component.css'],
   standalone:  false
})
export class WindowTitleComponent extends BaseComponent
{
    @Input()
    public title: string                      = "";

    @Input()
    public value: number | string | undefined;
}