import { Base } from '@Base/';

// export { NetworksCommon } from '@Common/Networks.class';


export class MobileNetwork extends Base
{
    private _categoryIcon: string;
    private _comment:      string;
    private _country:      string;
    private _name:         string;
    private _plmn:         string;
    private _primary:      boolean;

    private _icon:         {[key: string]: any} = {};


    constructor(data?: any)
    { 
        super ();

        if (data) {
            this._categoryIcon = data.categoryIcon    ? data.categoryIcon
                : (data._categoryIcon    ? data._categoryIcon    : this._categoryIcon);

            this._comment      = data.comment         ? data.comment
                : (data._comment         ? data._comment         : this._comment);

            this._country      = data.operatorCountry ? data.operatorCountry
                : (data._operatorCountry ? data._operatorCountry : this._country);

            this._name         = data.operator        ? data.operator
                : (data._operator        ? data._operator        : this._name);

            this._plmn         = data.plmn            ? data.plmn
                : (data._plmn            ? data._plmn            : this._plmn);

            this._primary      = data.base            ? data.base
                : (data._base            ? data._base            : this._primary);

            // Icon
            this._icon.path    = data.icon            ? data.icon
                : (data._icon            ? data._icon            : this._icon.path);
                
            this._icon.scale   = data.iconScale       ? data.iconScale
                : (data._iconScale       ? data._iconScale       : this._icon.scale);

            this._icon.x       = data.iconX           ? data.iconX
                : (data._iconX           ? data._iconX           : this._icon.x);

            this._icon.y       = data.iconY           ? data.iconY
                : (data._iconY           ? data._iconY           : this._icon.y);
        }

  //      console.log(JSON.stringify(this));
    }


    //
    // Getters
    //
    public static get(obj: any): MobileNetwork
    {
        return (new this(obj));
    }


    public get icon(): object
    {
        return this._icon;
    }


    public get name(): string
    {
        return this._name;
    }


    public get plmn(): string
    {
        return this._plmn;
    }


    public get primary(): boolean
    {
        return this._primary;
    }
}